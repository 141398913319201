import gql from "graphql-tag";

import { Image, ImagePreview } from "./gallery.js";
import {
    Copyright,
    EngagementInfo,
    FeedConnection,
    LangCode,
} from "./_common.js";
import { App } from "./app.js";

export interface GetNewsVariables {
    id: string;
}
export interface News<C, I, A = undefined, F = undefined> {
    id: string;
    date: string;
    updatedAt: string;
    featured: boolean;
    contentLangs: LangCode[];
    content: C;
    promoApps: A[];
    copyright: Copyright;
    featuredNews: F[] | null;
    engagementInfo: EngagementInfo;
    image: I;
}

export interface NewsContent {
    title: string;
    metaTitle: string;
    preview: string;
    text: string;
    readingTime: number | null;
    auto: boolean;
    promoText: string | null;
}

export type NewsItem = News<NewsContent, Image, App, NewsForFeed>;

export interface GetNewsData {
    companyNews: NewsItem | null;
}

export const GET_NEWS = gql`
    query getCompanyNews($id: CompanyNewsID!) {
        companyNews(id: $id) {
            id
            copyright {
                author
                url
            }
            date
            updatedAt
            featured
            content {
                title
                metaTitle
                preview
                text
                readingTime
            }
            image {
                id
                content {
                    name
                    sourceUrl
                    thumbnails {
                        fileName
                        width
                        height
                    }
                    copyrights {
                        author
                        url
                    }
                }
            }
            engagementInfo {
                facebook
                messenger
                twitter
                telegram
                whatsapp
                native
            }
        }
    }
`;

export interface GetNewsFeedVariables {
    first: number;
    after?: string;
}

type NewsContentForFeed = Pick<NewsContent, "title" | "preview">;

export type NewsForFeed = Pick<
    News<NewsContentForFeed, ImagePreview>,
    "id" | "content" | "date" | "featured" | "image"
>;
export interface GetNewsFeedData {
    companyNewsFeed: FeedConnection<NewsForFeed> | null;
}
export const GET_NEWS_FEED = gql`
    query getCompanyNewsFeed($first: Int, $after: CompanyNewsCursor) {
        companyNewsFeed(first: $first, after: $after) {
            totalCount
            offset
            edges {
                cursor
                node {
                    id
                    date
                    featured
                    content {
                        title
                    }
                    image {
                        id
                        content {
                            name
                            sourceUrl
                            thumbnails {
                                fileName
                                width
                                height
                            }
                        }
                    }
                }
            }
        }
    }
`;
