import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ApolloClient } from "@apollo/client/core/ApolloClient.js";
import { ApolloProvider } from "@apollo/client/react/context/ApolloProvider.js";
import { InMemoryCache } from "@apollo/client/cache/inmemory/inMemoryCache.js";
import { HttpLink } from "@apollo/client/link/http/HttpLink.js";
import { ApolloLink } from "@apollo/client/link/core/ApolloLink.js";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries/index.js";
import { sha256 } from "crypto-hash";
import { isDev, MODE, VERSION } from "./config/env.js";
import AppRoot from "./components/AppRoot.js";
import ScrollToTop from "./components/common/ScrollToTop.js";
import { VITO_APP_ID } from "../_common/config.js";
import possibleTypes from "./graphql/possible-types.js";
import { typePolicies } from "./graphql/type-policies.js";
import resolvers from "./graphql/resolvers.js";

const history = createBrowserHistory();

const persistedQueryLink = createPersistedQueryLink({
    sha256,
    useGETForHashedQueries: true,
});

const httpLink = new HttpLink({
    uri: "/api",
    headers: {
        "X-Vito-App-Id": VITO_APP_ID,
        "X-Vito-App-Ver": VERSION,
    },
});

const cache = new InMemoryCache({
    possibleTypes,
    typePolicies,
}).restore((window as any).__APOLLO_STATE__);

const link = ApolloLink.from([persistedQueryLink, httpLink]);

const client = new ApolloClient({
    link,
    cache,
    resolvers,
});

const container = document.getElementById("root");
const app = (
    <React.StrictMode>
        <ApolloProvider client={client}>
            <Router history={history}>
                <ScrollToTop />
                <AppRoot />
            </Router>
        </ApolloProvider>
    </React.StrictMode>
);

if (MODE === "ui-development") {
    const root = createRoot(container!);
    root.render(app);
} else {
    hydrateRoot(container!, app, {
        onRecoverableError: isDev
            ? e => console.error("Hydration error:", e)
            : undefined,
    });
}
