import React from "react";
import VideoFrame from "../product/VideoFrame.js";
import ImageLoader from "./ImageLoader.js";
import { IMediaItem, MediaItemPreview } from "../../graphql/queries/gallery.js";
import ImageItem, { SrcSetSizes } from "./ImageItem.js";

interface Props {
    mediaItem: IMediaItem | MediaItemPreview | null | undefined;
    alt?: string;
    previewIfVideo?: boolean;
    showPlaceholder?: boolean;
    srcSetSizes: SrcSetSizes;
}

const MediaItem: React.FC<Props> = ({
    mediaItem,
    alt,
    previewIfVideo,
    showPlaceholder,
    srcSetSizes,
}) => {
    if (!mediaItem) return null;
    if (mediaItem.__typename === "Image") {
        return (
            <ImageItem
                image={mediaItem}
                srcSetSizes={srcSetSizes}
                alt={alt}
                showPlaceholder={showPlaceholder}
            />
        );
    }
    if (mediaItem.__typename === "YoutubeVideo") {
        const { sourceUrl, aspectRatio, thumbnailUrls, name } =
            mediaItem.content;
        if (previewIfVideo)
            return (
                <ImageLoader
                    aspectRatio={aspectRatio}
                    src={thumbnailUrls[0]}
                    alt={name || undefined}
                />
            );
        return <VideoFrame aspectRatio={aspectRatio} src={sourceUrl} />;
    }
    console.error(`MediaItem type not supported`);
    return null;
};

export default MediaItem;
