import gql from "graphql-tag";
import { AppPlatform } from "./app.js";

export interface SendMessageInput {
    to: string;
    source: string;
    productInfo: {
        productId?: string;
        platform?: AppPlatform;
        appId?: string;
    };
    email: string;
    name: string;
    message: string;
}
export interface SendMessagePayload {
    ok: boolean;
    message: string;
}
export const CREATE_SUPPORT_MESSAGE = gql`
    mutation sendMessage($input: SendMessageInput!) {
        sendMessage(input: $input) {
            ok
            message
        }
    }
`;
