import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { COMPANY_NAME } from "../../../_common/config.js";
import SwitchPageTracker from "../common/SwitchPageTracker.js";

interface Props {
    value?: string;
    isHome?: boolean;
}

const HtmlTitle: React.FC<Props> = ({ value, isHome = false }) => {
    const { pathname, search } = useLocation();
    return (
        <>
            <SwitchPageTracker
                pathname={pathname}
                search={search}
                title={value ? `${value} | ${COMPANY_NAME}` : COMPANY_NAME}
            />
            <Helmet
                defaultTitle={COMPANY_NAME}
                titleTemplate={isHome ? "" : `%s | ${COMPANY_NAME}`}
            >
                {value && <title>{value}</title>}
            </Helmet>
        </>
    );
};

export default HtmlTitle;
