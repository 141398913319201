import React, { useState } from "react";

import { ANGLE_LEFT } from "../../config/icons.js";
import { Faq } from "../../graphql/queries/products.js";
import Icon from "../svg/Icon.js";
import {
    answerTab,
    arrow,
    openedAnswer,
    questionTab,
    rotatedArrow,
    text,
    wrapper,
} from "./AccordionItem.css.js";

interface Props {
    faq: Faq;
    id: string;
}
const AccordionItem: React.FC<Props> = ({ faq: { question, answer }, id }) => {
    const [questionId, setQuestionId] = useState("");
    const answerIsOpened = questionId === id;
    const questionClick = () => {
        if (questionId === id) {
            setQuestionId("");
        } else {
            setQuestionId(id);
        }
    };
    return (
        <>
            <div className={wrapper}>
                <div
                    className={questionTab}
                    onClick={questionClick}
                    style={{
                        backgroundColor: answerIsOpened ? "#dedede" : "inherit",
                    }}
                >
                    <p className={text}>{question}</p>
                    <Icon
                        className={answerIsOpened ? rotatedArrow : arrow}
                        path={ANGLE_LEFT}
                    />
                </div>
                <div className={answerIsOpened ? openedAnswer : answerTab}>
                    {answer}
                </div>
            </div>
        </>
    );
};

export default AccordionItem;
