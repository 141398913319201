import React from "react";
import { theme } from "../styles/theme.css.js";

interface Props {
    width?: number | string;
    height?: number | string;
    fill?: string;
    className?: string;
    showFrame?: boolean;
}

const Logo: React.FC<Props> = ({
    width = "45",
    height = "100%",
    fill,
    className,
    showFrame = false,
}) => {
    const viewBox = showFrame ? "0 -25 512 512" : "30 5 482 482";
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBox}
            fill={fill || theme.colors.font}
        >
            <rect
                x="242"
                y="189"
                height="16"
                width="69"
                rx="8"
                transform="rotate(77 242 189)"
            />
            <circle cx="257.5" cy="283.5" r="19" />
            <rect
                x="156"
                y="340"
                height="16"
                width="88"
                rx="8"
                transform="rotate(-33.5 156 340)"
            />
            <circle cx="135" cy="363" r="22.1" />
            <rect
                x="79"
                y="295"
                height="16"
                width="60"
                rx="8"
                transform="rotate(46 79 295)"
            />
            <circle cx="54" cy="279" r="22.1" />
            <rect
                x="73"
                y="255"
                height="16"
                width="148"
                rx="8"
                transform="rotate(-35 73 255)"
            />
            <circle cx="226.5" cy="158.5" r="26" />
            <rect
                x="251"
                y="138"
                height="16"
                width="66"
                rx="8"
                transform="rotate(-25.5 251 138)"
            />
            <circle cx="337.5" cy="106.5" r="19" />
            <rect
                x="364"
                y="103"
                height="16"
                width="71"
                rx="8"
                transform="rotate(12 364 103)"
            />
            <circle cx="459" cy="133" r="22.1" />
            {showFrame ? (
                <rect
                    x="6"
                    y="-19"
                    height="500"
                    width="500"
                    rx="100"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="10"
                />
            ) : null}
        </svg>
    );
};

export default Logo;
