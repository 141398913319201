import React, { useContext } from "react";
import { useLocation } from "react-router";

import { AppIcon } from "../../graphql/queries/app.js";
import Content from "../../i18n/content.js";
import ConfigContext from "../../config/config-context.js";
import { makeIconUrl } from "../../helpers/utils.js";
import { gaConversionLinkClick } from "../../helpers/googleAnalytics.js";
import { siteButton, subTitle, textWrapper } from "../product/Intro.css.js";
import {
    buttonContainer,
    downloadAppIcon,
    productIntroTitle,
} from "../styles/common.css.js";
import { downloadAppButton, introStyle, solarTrailLogo } from "./styles.css.js";

interface Props {
    backgroundImage: string | undefined;
    title: string;
    subtitle: string;
    link: {
        url: string;
        text: string;
    } | null;
    typeAboutMediaItem: string;
    icon: AppIcon | null;
    productId: string;
}
const Intro: React.FC<Props> = ({
    backgroundImage,
    title,
    subtitle,
    link,
    typeAboutMediaItem,
    icon,
    productId,
}) => {
    const {
        productPage: {
            introSection: { videoButton, downloadButton },
        },
    } = useContext(Content);
    const { pathname } = useLocation();

    const { conversionDomains } = useContext(ConfigContext);
    const iconUrl = icon ? makeIconUrl(icon.sourceUrl, icon.sizes) : null;

    const { hostname } = link ? new URL(link.url) : { hostname: "" };

    return (
        <section
            style={{ backgroundImage: `url(${backgroundImage})` }}
            className={introStyle}
        >
            <div className={textWrapper}>
                <p className={subTitle}>A COLLABORATION WITH</p>
                <img
                    src="/assets/images/solar-trail.png"
                    className={solarTrailLogo}
                />
                <h1 className={productIntroTitle}>{title}</h1>
                <p className={subTitle}>{subtitle}</p>
            </div>
            <div className={buttonContainer}>
                {conversionDomains.includes(hostname) && link ? (
                    <a
                        href={link.url}
                        onClick={() =>
                            gaConversionLinkClick(
                                "intro_button",
                                pathname,
                                productId,
                                "product_page",
                            )
                        }
                        className={downloadAppButton}
                    >
                        <img
                            src={iconUrl || undefined}
                            className={downloadAppIcon}
                        />
                        {downloadButton}
                    </a>
                ) : link ? (
                    <a className={siteButton} href={link.url}>
                        {link.text}
                    </a>
                ) : null}

                {typeAboutMediaItem === "YoutubeVideo" ? (
                    <a href="#video" className={videoButton}>
                        {videoButton}
                    </a>
                ) : null}
            </div>
        </section>
    );
};

export default Intro;
