import React, { useContext } from "react";

import Content from "../../i18n/content.js";
import ImageSlider, { ImageSlideContent } from "../slider/ImageSlider.js";
import { commonSectionTitle, container } from "../styles/common.css.js";

interface Props {
    featuresSlides: ImageSlideContent[];
}
const Features: React.FC<Props> = ({ featuresSlides }) => {
    const {
        productPage: {
            featuresSection: { title },
        },
    } = useContext(Content);
    return (
        <div className={container.flex800}>
            <div>
                <h2 className={commonSectionTitle}>{title}</h2>
                <ImageSlider
                    slides={featuresSlides}
                    delaySec={3}
                    srcSetSizes={{ desktop: 770, laptop: 770, tablet: 538 }}
                />
            </div>
        </div>
    );
};

export default Features;
