import { circle, spinnerContainer } from "./CircleSpinner.css.js";

import React from "react";

const CircleSpinner: React.FC = () => (
    <div className={spinnerContainer}>
        <span className={circle} />
        <span className={circle} />
        <span className={circle} />
    </div>
);

export default CircleSpinner;
