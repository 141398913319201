import React from "react";

export interface ConfigData {
    conversionDomains: string[];
    googleAnalyticsId?: string;
    facebookPixelId?: string;
    twitterUsername?: string;
    newsFeedPageItemsCount: number;
}

const ConfigContext = React.createContext<ConfigData>(undefined!);

export const buildConfig = (data?: Partial<ConfigData> | null): ConfigData => {
    return {
        conversionDomains: [],
        newsFeedPageItemsCount: 9,
        ...data,
    };
};

export default ConfigContext;
