import React, { useContext } from "react";
import Content from "../../i18n/content.js";
import MarkdownRenderer from "../common/MarkdownRenderer.js";

import { commonSectionTitle, container } from "../styles/common.css.js";
import { wrapper } from "./HowToUse.css.js";
interface Props {
    text: string;
    productName: string;
}
const HowToUse: React.FC<Props> = ({ text, productName }) => {
    const {
        productPage: {
            howToUseSection: { title },
        },
    } = useContext(Content);

    return (
        <div className={container.flex800}>
            <div className={wrapper}>
                <h2 className={commonSectionTitle}>
                    {title.replace("%PRODUCT_NAME%", productName)}
                </h2>
                <MarkdownRenderer source={text} />
            </div>
        </div>
    );
};

export default HowToUse;
