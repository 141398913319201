import { Children } from "react";
import React from "react";

function TableCell({ children }: { children: JSX.Element[] }) {
    const content = Children.map(children, c => {
        if (c.props.value === "<br/>") {
            return <br />;
        }
        return c;
    });
    return <td>{content}</td>;
}
const createClassName = (children: JSX.Element[]) => {
    const expression = /<!--rehype:class=(\w+)-->/m;
    const classArray = Children.map(children, c => {
        if (c.props.value) {
            const matchResults = c.props.value.match(expression);
            return matchResults ? matchResults[1] : null;
        }
        return null;
    });
    const className = classArray[0];
    return className;
};
type HeadingsProps = {
    level: number;
    children: JSX.Element[];
};
const Headings: React.FC<HeadingsProps> = ({ level, children }) => {
    const className = createClassName(children);
    switch (level) {
        case 1:
            return <h1 className={className}>{children}</h1>;
        case 2:
            return <h2 className={className}>{children}</h2>;
        case 3:
            return <h3 className={className}>{children}</h3>;
        case 4:
            return <h4 className={className}>{children}</h4>;
        default:
            return <h5 className={className}>{children}</h5>;
    }
};
interface ParagraphProps {
    children: JSX.Element[];
}
const Paragraph: React.FC<ParagraphProps> = ({ children }) => {
    const className = createClassName(children);
    return <p className={className}>{children}</p>;
};
export const markdownTableRenderers = {
    tableCell: TableCell,
    heading: Headings,
    paragraph: Paragraph,
};
