import React from "react";
import { useLocation } from "react-router";
import { TRUSTPILOT_BADGE, TRUSTPILOT_PAGE_URL } from "../../config/content.js";
import { gaExternalLinkClick } from "../../helpers/googleAnalytics.js";

interface Props {
    location: string;
}
const TrustPilotLink: React.FC<Props> = ({ location }) => {
    const { pathname } = useLocation();

    return (
        <div style={{ textAlign: "center" }}>
            <a
                href={TRUSTPILOT_PAGE_URL}
                target="_blank"
                onClick={() =>
                    gaExternalLinkClick(TRUSTPILOT_PAGE_URL, pathname, location)
                }
                rel="noopener"
            >
                <img
                    src={TRUSTPILOT_BADGE}
                    alt="Trustpilot"
                    style={{
                        width: "195px",
                        height: "47px",
                    }}
                ></img>
            </a>
        </div>
    );
};

export default TrustPilotLink;
