import React from "react";

import { DOMAIN_URL } from "../../config/env.js";
import { AwardContent } from "../../graphql/queries/products.js";
import { logo, award, wrapper } from "./Awards.css.js";

interface Props {
    awards: AwardContent[];
}
const Awards: React.FC<Props> = ({ awards }) => {
    return (
        <div className={wrapper}>
            {awards.map(({ url, imgUrl, alt }, index) =>
                url ? (
                    <a
                        className={award}
                        key={`${alt}${index}`}
                        href={url}
                        rel="nofollow noopener noreferrer"
                    >
                        <img
                            src={`${DOMAIN_URL}${imgUrl}`}
                            alt={alt}
                            className={logo}
                        />
                    </a>
                ) : (
                    <div className={award} key={`${alt}${index}`}>
                        <img
                            src={`${DOMAIN_URL}${imgUrl}`}
                            alt={alt}
                            className={logo}
                        />
                    </div>
                ),
            )}
        </div>
    );
};

export default Awards;
