import React, { useEffect } from "react";
import { trackPageView } from "../../helpers/googleAnalytics.js";

interface Props {
    pathname: string;
    search: string;
    title: string;
}

const SwitchPageTracker: React.FC<Props> = ({ pathname, search, title }) => {
    useEffect(() => {
        trackPageView(title, pathname, search);
    }, [title, pathname, search]);

    return null;
};

export default SwitchPageTracker;
