import React from "react";

import { Partner } from "../../graphql/queries/home.js";
import { homeSectionTitle } from "../styles/common.css.js";
import { imgWrapper, logo } from "./Partners.css.js";

interface Props {
    title: string;
    partners: Partner[];
}
const Partners: React.FC<Props> = ({ title, partners }) => {
    return (
        <section>
            <h2 className={homeSectionTitle}>{title}</h2>
            <div className={imgWrapper}>
                {partners.map(({ imageUrl, url, content: { name }, rel }) => (
                    <a
                        key={url}
                        href={url}
                        rel={rel || "nofollow noopener noreferrer"}
                    >
                        <img src={imageUrl} alt={name} className={logo} />
                    </a>
                ))}
            </div>
        </section>
    );
};

export default Partners;
