import gql from "graphql-tag";

export interface GetContactsContentData<T> {
    staticContent: {
        id: string;
        content: T;
    };
}

export const GET_CONTACTS_CONTENT = gql`
    query getContactsContent {
        staticContent(id: "vito-site-contacts") {
            id
            content
        }
    }
`;
