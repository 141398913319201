import React, { useRef } from "react";
import { activeSlide, reviewSlide, slide } from "./Slide.css.js";

interface Props {
    isActive: boolean;
    onRightHandler: any;
    onLeftHandler: any;
    children: React.ReactNode;
    isReviewSlide?: boolean;
}

const Slide: React.FC<Props> = ({
    isActive,
    onRightHandler,
    onLeftHandler,
    children,
    isReviewSlide,
}) => {
    const startingX = useRef<number>(0);
    const endingX = useRef<number>(0);
    const startingY = useRef<number>(0);
    const endingY = useRef<number>(0);
    // TODO: all the code with body is bad & needs improvement
    const body =
        typeof document !== "undefined" ? document.querySelector("body") : null;

    const calculateSwipeAngle = () => {
        const differenceX = Math.abs(startingX.current - endingX.current);
        const differenceY = Math.abs(startingY.current - endingY.current);
        const swipeAngle =
            (Math.atan(differenceY / differenceX) * 180) / Math.PI;
        return swipeAngle;
    };
    return (
        <div
            className={`${isActive ? activeSlide : null} ${
                isReviewSlide ? reviewSlide : slide
            }`}
            onTouchStart={e => {
                startingX.current = e.changedTouches[0].clientX;
                startingY.current = e.changedTouches[0].clientY;
            }}
            onTouchMove={() => {
                if (calculateSwipeAngle() < 45) {
                    if (body) {
                        body.ontouchmove = () => {
                            return false;
                        };
                    }
                } else {
                    if (body) {
                        body.ontouchmove = () => {
                            return true;
                        };
                    }
                }
            }}
            onTouchEnd={e => {
                endingX.current = e.changedTouches[0].clientX;
                endingY.current = e.changedTouches[0].clientY;

                if (
                    startingX.current - endingX.current > 0 &&
                    calculateSwipeAngle() < 45
                ) {
                    onRightHandler();
                } else if (
                    startingX.current - endingX.current < 0 &&
                    calculateSwipeAngle() < 45
                ) {
                    onLeftHandler();
                }
                if (body) {
                    body.ontouchmove = () => {
                        return true;
                    };
                }
            }}
        >
            {children}
        </div>
    );
};

export default Slide;
