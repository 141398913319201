import React, { useReducer, useRef, useEffect, useCallback } from "react";

import { Link } from "react-router-dom";

import { ANGLE_RIGHT, ANGLE_LEFT } from "../../config/icons.js";
import { Image } from "../../graphql/queries/gallery.js";
import ImageItem, { SrcSetSizes } from "../common/ImageItem.js";
import Slide from "./Slide.js";
import { btnIcon, dot, dots } from "./commonSlider.css.js";
import Icon from "../svg/Icon.js";
import {
    activeDescWrapper,
    descriptions,
    descriptionText,
    descWrapper,
    featuresDescriptions,
    leftButton,
    readMoreLink,
    rightButton,
    slideLink,
    slidesList,
    wrapper,
} from "./ImageSlider.css.js";
import { SLIDER_ASPECT_RATIO } from "../../config/content.js";

export interface ImageSlideContent {
    id: string | undefined;
    productId?: string;
    image: Image | null;
    description?: string;
}
interface Props {
    slides: ImageSlideContent[];
    delaySec: number;
    button?: string;
    srcSetSizes: SrcSetSizes;
}

type SliderReduceAction =
    | { type: "set"; index: number }
    | { type: "right" }
    | { type: "left" }
    | { type: "autoSwitch" };

type SliderReducer = (
    currentIndex: number,
    action: SliderReduceAction,
) => number;

const ImageSlider: React.FC<Props> = ({
    slides,
    delaySec,
    button,
    srcSetSizes,
}) => {
    const intervalRef = useRef<any>(undefined);
    const timeOutRef = useRef<any>(undefined);

    const [width, height] = SLIDER_ASPECT_RATIO.split(":").map(size =>
        Number(size),
    );
    const padding = (height / width) * 100;

    const setAutoPlay = useCallback(() => {
        intervalRef.current = setInterval(
            () => dispatch({ type: "autoSwitch" }),
            delaySec * 1000,
        );
    }, [delaySec]);

    const delayAutoPlay = () => {
        clearInterval(intervalRef.current);
        clearTimeout(timeOutRef.current);
        timeOutRef.current = setTimeout(setAutoPlay, 10000);
    };

    const [activeIndex, dispatch] = useReducer<SliderReducer, number>(
        (currentIndex, action) => {
            switch (action.type) {
                case "right": {
                    delayAutoPlay();
                    return currentIndex === slides.length - 1
                        ? 0
                        : currentIndex + 1;
                }
                case "left": {
                    delayAutoPlay();
                    return currentIndex === 0
                        ? slides.length - 1
                        : currentIndex - 1;
                }

                case "set": {
                    delayAutoPlay();
                    return action.index;
                }
                case "autoSwitch": {
                    return currentIndex === slides.length - 1
                        ? 0
                        : currentIndex + 1;
                }
                default:
                    throw new Error("Invalid action type.");
            }
        },
        0,
        () => 0,
    );

    useEffect(() => {
        setAutoPlay();
        return () => {
            clearInterval(intervalRef.current);
            clearTimeout(timeOutRef.current);
        };
    }, [setAutoPlay]);
    const isFeaturesSlider = !!slides[0].description;
    return (
        <div className={wrapper}>
            {slides[0].image && (
                <>
                    <div
                        className={slidesList}
                        style={{ paddingBottom: `${padding}%` }}
                    >
                        {slides.map(({ id, productId, image }, index) =>
                            productId ? (
                                <Link
                                    to={`apps/${productId}`}
                                    key={`${id}${index}`}
                                    className={slideLink}
                                >
                                    <Slide
                                        isActive={index === activeIndex}
                                        onRightHandler={() =>
                                            dispatch({ type: "right" })
                                        }
                                        onLeftHandler={() =>
                                            dispatch({ type: "left" })
                                        }
                                    >
                                        <ImageItem
                                            image={image}
                                            srcSetSizes={srcSetSizes}
                                        />
                                    </Slide>
                                </Link>
                            ) : (
                                <Slide
                                    key={`${id}${index}`}
                                    isActive={index === activeIndex}
                                    onRightHandler={() =>
                                        dispatch({ type: "right" })
                                    }
                                    onLeftHandler={() =>
                                        dispatch({ type: "left" })
                                    }
                                >
                                    <ImageItem
                                        image={image}
                                        srcSetSizes={srcSetSizes}
                                    />
                                </Slide>
                            ),
                        )}
                        <div
                            onClick={() => dispatch({ type: "left" })}
                            className={leftButton}
                        >
                            <Icon path={ANGLE_LEFT} className={btnIcon} />
                        </div>
                        <div
                            onClick={() => dispatch({ type: "right" })}
                            className={rightButton}
                        >
                            <Icon path={ANGLE_RIGHT} className={btnIcon} />
                        </div>
                    </div>
                    <div className={dots}>
                        {slides.map(({ id }, index) => (
                            <span
                                className={dot}
                                key={`${id}${index}`}
                                onClick={() => dispatch({ type: "set", index })}
                                style={{
                                    backgroundColor:
                                        index === activeIndex
                                            ? "#000"
                                            : "#D9D9D9",
                                }}
                            />
                        ))}
                    </div>

                    <div
                        className={
                            isFeaturesSlider
                                ? featuresDescriptions
                                : descriptions
                        }
                    >
                        {slides.map(({ id, description, productId }, index) => (
                            <DescriptionComponent
                                linkText={button}
                                link={`apps/${productId}`}
                                key={`${id}${index}`}
                                isActive={index === activeIndex}
                                description={description}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

interface DescriptionComponentProps {
    isActive: boolean;
    linkText?: string;
    link: string;
    description: string | undefined;
}
const DescriptionComponent: React.FC<DescriptionComponentProps> = ({
    description,
    linkText,
    link,
    isActive,
}) => {
    return (
        <div className={isActive ? activeDescWrapper : descWrapper}>
            <div className={descriptionText}>{description}</div>
            {linkText ? (
                <Link to={link} className={readMoreLink}>
                    {linkText}
                </Link>
            ) : null}
        </div>
    );
};

export default ImageSlider;
