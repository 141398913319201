import React from "react";
import { Link } from "react-router-dom";

import { NewsItemForHome } from "../../graphql/queries/home.js";
import { formatDate } from "../../helpers/utils.js";
import { FeedConnection } from "../../graphql/queries/_common.js";
import NewsItem from "./NewsItem.js";
import { anchorButton, homeSectionTitle } from "../styles/common.css.js";
import { newsFeed, newsWrapper } from "./News.css.js";

interface Props {
    news: Pick<FeedConnection<NewsItemForHome>, "edges"> | null;
    title: string;
    moreNewsButton: string;
}
const News: React.FC<Props> = ({ news, title, moreNewsButton }) => {
    if (news && news.edges) {
        return (
            <section className={newsWrapper}>
                <h2 className={homeSectionTitle}>{title}</h2>
                <div className={newsFeed}>
                    {news.edges.map(
                        ({
                            node: {
                                id,
                                image,
                                content: { title },
                                date,
                            },
                        }) => {
                            return (
                                <NewsItem
                                    key={id}
                                    image={image}
                                    date={formatDate(date, "en")}
                                    title={title}
                                    link={`/news/${id}`}
                                    srcSetSizes={{
                                        desktop: 368,
                                        laptop: 268,
                                        tablet: 536,
                                    }}
                                />
                            );
                        },
                    )}
                </div>
                <Link className={anchorButton} to={`/news`}>
                    {moreNewsButton}
                </Link>
            </section>
        );
    }
    return null;
};

export default News;
