import React from "react";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";

import { container } from "../styles/common.css.js";
import About from "./About.js";
import Apps from "./Apps.js";
import Intro from "./Intro.js";
import News from "./News.js";
import Partners from "./Partners.js";
import ErrorMsg from "../ErrorMsg.js";
import Spinner from "../Spinner.js";
import {
    GetHomeContentData,
    GetHomeContentVariables,
    GET_HOME_CONTENT,
} from "../../graphql/queries/home.js";
import MetaTags from "../MetaTags.js";
import { OPEN_GRAPH_HOME_IMAGE_PATH } from "../../config/content.js";
import { COMPANY_NAME } from "../../../_common/config.js";
import { makeBackgroundImgUrl } from "../../helpers/utils.js";

const first = 3;
const Home: React.FC = () => {
    const { data, loading, error } = useQuery<
        GetHomeContentData,
        GetHomeContentVariables
    >(GET_HOME_CONTENT, {
        variables: {
            first: first,
        },
    });

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <ErrorMsg type={"default"} />;
    }
    if (data && data.homePage) {
        const { companyNewsFeed, app } = data;
        const { featuredProducts } = data;
        const { content, mediaItems } = data.homePage;

        const introBackgroundImage = mediaItems?.find(
            m =>
                !!m &&
                m.__typename === "Image" &&
                m.id === content.introSection.backgroundImageId,
        );

        const newTitle = content.introSection.title.replace(
            /%COMPANY_NAME%/,
            COMPANY_NAME,
        );

        const slides = featuredProducts.map(item => {
            return {
                productId: item.id,
                id: item.homeSliderImage.id,
                image: item.homeSliderImage,
            };
        });

        return (
            <>
                <MetaTags
                    path={""}
                    isHome={true}
                    title={content.metaTitle || newTitle}
                    description={
                        content.metaDescription ||
                        content.aboutSection.description
                    }
                    openGraphImage={[OPEN_GRAPH_HOME_IMAGE_PATH, "1200x630"]}
                />
                <Intro
                    backgroundImage={makeBackgroundImgUrl(introBackgroundImage)}
                    title={content.introSection.title}
                    buttonText={content.introSection.downloadAppButton}
                    buttonUrl={content.introSection.downloadAppButtonLink}
                    icon={
                        app && app.promoApps.length !== 0
                            ? app.promoApps[0].icon
                            : undefined
                    }
                />
                <div className={container.flex}>
                    <About
                        description={content.aboutSection.description}
                        aboutButton={content.aboutSection.aboutUsButton}
                    />
                    {slides && slides.length !== 0 && (
                        <Apps
                            title={content.appsSection.title}
                            readMoreButton={content.appsSection.readMoreButton}
                            allAppsButton={content.appsSection.allAppsButton}
                            slides={slides}
                        />
                    )}
                    <News
                        news={companyNewsFeed}
                        title={content.newsSection.title}
                        moreNewsButton={content.newsSection.moreNewsButton}
                    />
                    {data.partners && (
                        <Partners
                            title={content.partnersSection.title}
                            partners={data.partners}
                        />
                    )}
                </div>
            </>
        );
    }
    return <ErrorMsg type={"default"} />;
};

export default Home;
