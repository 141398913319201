import React from "react";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";

import Header from "./Header.js";
import Footer from "./Footer.js";
import Content, { buildContent } from "../../i18n/content.js";
import Spinner from "../Spinner.js";
import RootErrorMsg from "./RootErrorMsg.js";
import {
    GetCommonContentData,
    GET_COMMON_CONTENT,
} from "../../graphql/queries/common-content.js";
import CookieConsent from "../common/CookieConsent.js";
import PromoAppContextProvider from "./PromoAppContextProvider.js";
import HtmlLang from "../helmet/HtmlLang.js";
import ButtonActions from "./ButtonActions.js";
import ConfigContext, { buildConfig } from "../../config/config-context.js";
import Head from "./Head.js";
import { mainContent, screenBottomActions } from "./Layout.css.js";

interface Props {
    children: React.ReactNode;
}
const Layout: React.FC<Props> = ({ children }) => {
    const { data, loading, error } =
        useQuery<GetCommonContentData>(GET_COMMON_CONTENT);
    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <RootErrorMsg />;
    }
    if (data) {
        return (
            <Content.Provider value={buildContent("en", data)}>
                <ConfigContext.Provider value={buildConfig(data.app?.config)}>
                    <PromoAppContextProvider>
                        <HtmlLang lang="en" />
                        <Head />
                        <Header />
                        <main className={mainContent}>{children}</main>
                        <Footer />
                        <div data-nosnippet className={screenBottomActions}>
                            <ButtonActions />
                            <CookieConsent />
                        </div>
                    </PromoAppContextProvider>
                </ConfigContext.Provider>
            </Content.Provider>
        );
    }
    return <RootErrorMsg />;
};

export default Layout;
