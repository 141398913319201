import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import React from "react";

import { OPEN_GRAPH_IMAGE_PATH } from "../../config/content.js";
import { COMPANY_NAME } from "../../../_common/config.js";
import {
    GetAboutContentData,
    GetStaticContentVariables,
    GET_ABOUT_CONTENT,
} from "../../graphql/queries/about.js";
import MarkdownRenderer from "../common/MarkdownRenderer.js";
import ErrorMsg from "../ErrorMsg.js";
import MetaTags from "../MetaTags.js";
import { container } from "../styles/common.css.js";
import Spinner from "../Spinner.js";
import Timeline from "./Timeline.js";
import { makeBackgroundImgUrl } from "../../helpers/utils.js";
import {
    companyName,
    introBackground,
    introSubtitle,
    introTitle,
    titleWrapper,
} from "./About.css.js";

interface Props {
    id: string;
    path: string;
}
interface ContentStructure {
    title: string;
    subtitle: string;
    metaTitle: string;
    metaDescription: string;
    introBackgroundImageId: string;
    text: string;
    history: string;
}

const About: React.FC<Props> = ({ id, path }) => {
    const { data, loading, error } = useQuery<
        GetAboutContentData<ContentStructure>,
        GetStaticContentVariables
    >(GET_ABOUT_CONTENT, {
        variables: {
            id,
        },
    });
    if (error) {
        return <ErrorMsg type={"default"} />;
    }

    if (loading) {
        return <Spinner />;
    }

    if (data && data.staticContent) {
        const {
            content: {
                title,
                subtitle,
                text,
                metaTitle,
                metaDescription,
                introBackgroundImageId,
                history,
            },
            mediaItems,
        } = data.staticContent;
        const introBackgroundImage = makeBackgroundImgUrl(
            mediaItems.find(image => image.id === introBackgroundImageId),
        );

        const titlePart = title.split(`${COMPANY_NAME}`)[0];

        return (
            <>
                <MetaTags
                    path={path}
                    title={metaTitle || title}
                    description={metaDescription}
                    openGraphImage={[OPEN_GRAPH_IMAGE_PATH, "1200x630"]}
                />
                <div
                    style={{
                        backgroundImage: `url(${introBackgroundImage})`,
                    }}
                    className={introBackground}
                >
                    <div className={titleWrapper}>
                        <h1 className={introTitle}>
                            {titlePart}
                            <span className={companyName}>{COMPANY_NAME}</span>
                        </h1>
                        <p className={introSubtitle}>{subtitle}</p>
                    </div>
                </div>
                <div className={container.flex800}>
                    <MarkdownRenderer source={text} className="hh" />
                    <Timeline timelineData={history} mediaItems={mediaItems} />
                </div>
            </>
        );
    }
    return <ErrorMsg type={"notFound"} />;
};

export default About;
