import type { FieldPolicy } from "@apollo/client/core/index.js";
import { FeedConnection } from "./queries/_common";
import { NewsForFeed } from "./queries/news";

const feedFieldPolicy: FieldPolicy<FeedConnection<NewsForFeed>> = {
    keyArgs: false,
    merge: (existing, incoming) => ({
        ...existing,
        ...incoming,
        edges: [...(existing?.edges || []), ...incoming.edges],
    }),
};
export const typePolicies = {
    Query: {
        fields: {
            companyNewsFeed: feedFieldPolicy,
        },
    },
};
