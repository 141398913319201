import React from "react";
import { withRouter, RouteComponentProps } from "react-router";

// Components:
import HtmlTitle from "../helmet/HtmlTitle.js";
import { container } from "../styles/common.css.js";
import { errorLayout, errorMessage, errorTitle } from "../ErrorMsg.css.js";

const RootErrorMsg: React.FC<RouteComponentProps> = ({ staticContext }) => {
    if (staticContext) {
        staticContext.statusCode = 500;
    }
    const title = "500";
    const message = "Oops, something bad happend. We are already fixing it...";
    return (
        <>
            <HtmlTitle value={title} />
            <div className={container.flex}>
                <div className={errorLayout}>
                    <h1 className={errorTitle}>{title}</h1>
                    <h2 className={errorMessage}>{message}</h2>
                </div>
            </div>
        </>
    );
};

export default withRouter(RootErrorMsg);
