import React from "react";
import { Link, useLocation } from "react-router-dom";

import { SitemapLinkData } from "../../graphql/queries/sitemap.js";
import { gaSitemapClick } from "../../helpers/googleAnalytics.js";
import { Edge } from "../../graphql/queries/_common.js";
import CircleSpinner from "../common/CircleSpinner.js";
import { itemLink, listContainer, listItem } from "./LinksList.css.js";

interface Props {
    links: Edge<SitemapLinkData>[];
    rootPath: string;
    loading: boolean;
}

const LinksList: React.FC<Props> = ({ links, rootPath, loading }) => {
    const { pathname } = useLocation();

    return (
        <ul className={listContainer}>
            {links.map(
                ({
                    node: {
                        id,
                        content: { title },
                    },
                }) => {
                    return (
                        <li className={listItem} key={id + rootPath}>
                            <Link
                                to={`${rootPath}/${id}`}
                                onClick={() => {
                                    gaSitemapClick(
                                        `${rootPath}/${id}`,
                                        pathname,
                                    );
                                }}
                                className={itemLink}
                            >
                                {title}
                            </Link>
                        </li>
                    );
                },
            )}
            {loading && <CircleSpinner />}
        </ul>
    );
};

export default LinksList;
