import React, { useReducer } from "react";
import { useLocation } from "react-router";
import { getDevicePlatform } from "../../helpers/utils.js";
import { App, ReleasedApp } from "../../graphql/queries/app.js";

interface PromoAppState {
    promoApp: ReleasedApp | null;
    isButtonVisible: boolean;
    search: string;
}

type PromoAppAction =
    | { type: "pick"; apps: App[] }
    | { type: "show" }
    | { type: "hide" };

type PromoAppSetter = (
    prevValue: PromoAppState,
    action: PromoAppAction,
) => PromoAppState;

type Dispatch = (action: PromoAppAction) => void;

export const PromoAppContext = React.createContext<ReleasedApp | null>(null);
export const PromoAppSetterContext = React.createContext<Dispatch>(() => {});
export const GetButtonVisibilityContext = React.createContext<boolean>(false);

const promoAppSetter = (
    state: PromoAppState,
    action: PromoAppAction,
): PromoAppState => {
    switch (action.type) {
        case "show":
            return {
                ...state,
                isButtonVisible: true,
            };
        case "hide":
            return {
                ...state,
                isButtonVisible: false,
            };
        case "pick": {
            const searchParams = new URLSearchParams(state.search);
            const utm_medium = searchParams.get("utm_medium");
            const platform = getDevicePlatform();

            return {
                ...state,
                promoApp:
                    (action.apps.find(
                        a =>
                            a.platform === platform &&
                            !!a.marketUrl &&
                            a.id !== utm_medium,
                    ) as ReleasedApp) || null,
            };
        }
        default:
            throw new Error("Invalid action type in promoAppSetter");
    }
};
interface Props {
    children: React.ReactNode;
}
const PromoAppContextProvider: React.FC<Props> = ({ children }) => {
    const { search } = useLocation();
    const [{ isButtonVisible, promoApp }, pickPromoApp] = useReducer<
        PromoAppSetter,
        PromoAppState
    >(
        promoAppSetter,
        {
            promoApp: null,
            isButtonVisible: false,
            search: search,
        },
        () => ({ promoApp: null, isButtonVisible: false, search: search }),
    );

    return (
        <PromoAppSetterContext.Provider value={pickPromoApp}>
            <PromoAppContext.Provider value={promoApp}>
                <GetButtonVisibilityContext.Provider value={isButtonVisible}>
                    {children}
                </GetButtonVisibilityContext.Provider>
            </PromoAppContext.Provider>
        </PromoAppSetterContext.Provider>
    );
};

export default PromoAppContextProvider;
