import React, { useContext } from "react";
import { ASSETS_PATH } from "../../config/content.js";
import Content from "../../i18n/content.js";
import { AppPlatform } from "../../graphql/queries/app.js";
import { storeButtonImg } from "./StoreBadge.css.js";

interface Props {
    platform: AppPlatform;
}

const STORE_BADGES = {
    ios: "appstore.svg",
    android: "googleplay.svg",
    huawei: "huaweistore.svg",
};

const StoreBadge: React.FC<Props> = ({ platform }) => {
    const {
        common: { storeBadges: alts },
    } = useContext(Content);
    return (
        <img
            src={`${ASSETS_PATH}/badges/en/${STORE_BADGES[platform]}`}
            alt={alts[platform]}
            className={storeButtonImg}
        />
    );
};

export default StoreBadge;
