import React, { useContext } from "react";

import { gaConversionLinkClick } from "../../helpers/googleAnalytics.js";
import { trackMarketUrl } from "../../helpers/utils.js";
import {
    GetButtonVisibilityContext,
    PromoAppContext,
} from "./PromoAppContextProvider.js";
import { ReleasedApp } from "../../graphql/queries/app.js";
import { useLocation } from "react-router";
import Content from "../../i18n/content.js";
import SkyTonightButton from "./SkyTonightButton.js";
import {
    buttonActionsContainer,
    buttonBlock,
    visibleButtonBlock,
    getButton,
} from "./ButtonActions.css.js";

const ButtonActions: React.FC = () => {
    const app = useContext(PromoAppContext);
    const isSkyTonightButton = app?.id.includes("starwalkpro");
    return (
        <div id="button-actions-container" className={buttonActionsContainer}>
            {app ? (
                <GetAppButtonBlock
                    app={app}
                    isSkyTonightButton={isSkyTonightButton}
                />
            ) : null}
        </div>
    );
};

interface GetAppButtonBlockProps {
    app: ReleasedApp;
    isSkyTonightButton?: boolean;
}

const GetAppButtonBlock: React.FC<GetAppButtonBlockProps> = ({
    app,
    isSkyTonightButton,
}) => {
    const {
        common: {
            actions: { getAppButton, downloadAppButton },
        },
    } = useContext(Content);

    const { pathname } = useLocation();
    const [, , page] = pathname.split("/");

    const isShown = useContext(GetButtonVisibilityContext);

    const getAppButtonText = getAppButton.replace("%{APP_NAME}", "");
    const downloadAppButtonText = downloadAppButton.replace("%{APP_NAME}", "");

    const onClick = () =>
        gaConversionLinkClick("get_app_button", pathname, app.id);

    const href = trackMarketUrl(
        app.marketUrl,
        app.platform,
        page || "home",
        "get-app-button",
    );

    return (
        <div
            id="get-app-button-block"
            className={isShown ? visibleButtonBlock : buttonBlock}
        >
            {isSkyTonightButton ? (
                <SkyTonightButton
                    href={href}
                    onClick={onClick}
                    getAppButtonText={downloadAppButtonText}
                    title={app.title}
                />
            ) : (
                <a
                    className={getButton}
                    id="get-app-button"
                    href={href}
                    onClick={onClick}
                >
                    {`${getAppButtonText}${app.title}`}
                </a>
            )}
        </div>
    );
};

export default ButtonActions;
