import gql from "graphql-tag";

export interface GetStaticContentVariables {
    id: string;
}

export interface GetStaticContentData<T> {
    staticContent: {
        id: string;
        content: T;
    };
}

export const GET_STATIC_CONTENT = gql`
    query getStaticContent($id: StaticContentID!) {
        staticContent(id: $id) {
            id
            contentLangs
            content
        }
    }
`;
