import React from "react";
import { Helmet } from "react-helmet";
import { COMPANY_NAME } from "../../../_common/config.js";

interface Props {
    url: string;
    title: string;
    description: string;
    openGraphImageURL: string;
    openGraphImageSize: string;
}

const HtmlOpenGraph: React.FC<Props> = ({
    url,
    title,
    description,
    openGraphImageURL,
    openGraphImageSize,
}) => {
    const [width, height] = openGraphImageSize.split("x");

    return (
        <Helmet>
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content={COMPANY_NAME} />
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={openGraphImageURL} />
            <meta property="og:image:secure_url" content={openGraphImageURL} />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:image:width" content={width} />
            <meta property="og:image:height" content={height} />
            <meta property="og:image:alt" content={description} />
        </Helmet>
    );
};

export default HtmlOpenGraph;
