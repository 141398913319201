import React from "react";

import { DOMAIN_URL } from "../config/env.js";
import HtmlTitle from "./helmet/HtmlTitle.js";
import HtmlDescription from "./helmet/HtmlDescription.js";
import HtmlAppleSmartBanner from "./helmet/HtmlAppleSmartBanner.js";
import HtmlCanonicalLink from "./helmet/HtmlCanonicalLink.js";
import HtmlAlternateLinks from "./helmet/HtmlAlternateLinks.js";
import HtmlOpenGraph from "./helmet/HtmlOpenGraph.js";
import HtmlTwitter from "./helmet/HtmlTwitter.js";
import HtmlNoIndex from "./helmet/HtmlNoIndex.js";

export interface MetaContent {
    title: string;
    description: string;
}

interface Props {
    path: string;
    isHome?: boolean;
    openGraphImage: string[];
    openGraphTwitterImageURL?: string;
    promoAppId?: string;
    noindex?: boolean;
}

const MetaTags: React.FC<Props & MetaContent> = ({
    path,
    title,
    isHome,
    description,
    openGraphImage,
    openGraphTwitterImageURL,
    promoAppId,
    noindex = false,
}) => {
    const [openGraphImageURL, openGraphImageSize] = openGraphImage;
    const canonicalLink = `${DOMAIN_URL}${path}`;

    const skipIndexing = noindex;

    return (
        <>
            <HtmlTitle value={title} isHome={isHome} />
            <HtmlDescription value={description} />
            {promoAppId && <HtmlAppleSmartBanner appId={promoAppId} />}
            {!skipIndexing ? (
                <>
                    <HtmlCanonicalLink value={canonicalLink} />
                    <HtmlAlternateLinks domainURL={DOMAIN_URL} path={path} />
                </>
            ) : (
                <HtmlNoIndex />
            )}
            <HtmlOpenGraph
                url={canonicalLink}
                title={title}
                description={description}
                openGraphImageURL={openGraphImageURL}
                openGraphImageSize={openGraphImageSize}
            />
            <HtmlTwitter
                title={title}
                description={description}
                openGraphTwitterImageURL={
                    openGraphTwitterImageURL || openGraphImageURL
                }
            />
        </>
    );
};

export default MetaTags;
