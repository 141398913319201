import React from "react";

import {
    AwardContent,
    ReviewSlideContent,
} from "../../graphql/queries/products.js";
import { container } from "../styles/common.css.js";
import Awards from "./Awards.js";
import Reviews from "./Reviews.js";
import { wrapper } from "./Assessment.css.js";

interface Props {
    reviews: ReviewSlideContent[];
    awards: AwardContent[];
}
const Assessment: React.FC<Props> = ({ reviews, awards }) => {
    return (
        <div className={wrapper}>
            <div className={container.flex800}>
                {reviews && reviews.length !== 0 && (
                    <Reviews reviews={reviews} />
                )}
                {awards ? <Awards awards={awards} /> : null}
            </div>
        </div>
    );
};

export default Assessment;
