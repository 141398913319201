import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";

import Content from "../../i18n/content.js";
import BurgerMenu from "./BurgerMenu.js";
import { ABOUT, PRODUCTS, CONTACTS, RSS, SUPPORT } from "../../config/icons.js";
import Icon from "../svg/Icon.js";
import VitoLogo from "../svg/VitoLogo.js";
import { gaNavClick } from "../../helpers/googleAnalytics.js";
import {
    activeMenuNavLink,
    iconContainerStyleVariants,
    logoContainer,
    menuGridContainer,
    menuIcon,
    menuItemStyleVariants,
    menuItemTitleStyleVariants,
    menuNavLink,
} from "./Menu.css.js";

interface Props {
    closeMenu: () => void;
    toggleMenu: () => void;
}

const Menu: React.FC<Props> = ({ closeMenu, toggleMenu }) => {
    const {
        common: {
            layout: {
                menu: { home, products, aboutUs, news, support, contactUs },
            },
        },
    } = useContext(Content);
    const { pathname } = useLocation();

    const menuItems = [
        {
            title: home,
            path: "/",
            key: "home",
            iconPath: "",
            exact: true,
        },
        {
            title: products,
            path: "/apps",
            key: "apps",
            iconPath: PRODUCTS,
        },
        {
            title: news,
            path: "/news",
            key: "news",
            iconPath: RSS,
        },
        {
            title: aboutUs,
            path: "/about",
            key: "about",
            iconPath: ABOUT,
        },
        {
            title: support,
            path: "/support",
            key: "support",
            iconPath: SUPPORT,
        },
        {
            title: contactUs,
            path: "/contacts",
            key: "contacts",
            iconPath: CONTACTS,
        },
    ];

    return (
        <ul className={menuGridContainer}>
            <BurgerMenu toggleMenu={toggleMenu} inputId="menu-state" />
            {menuItems.map(({ key, path, title, iconPath, exact }) => (
                <li key={key} className={menuItemStyleVariants[key]}>
                    <NavLink
                        exact={exact}
                        to={path}
                        onClick={() => {
                            gaNavClick(key, "menu", pathname);
                            closeMenu();
                        }}
                        activeClassName={activeMenuNavLink}
                        className={menuNavLink}
                    >
                        {key === "home" ? (
                            <span className={logoContainer}>
                                <VitoLogo width="2rem" height="2rem" />
                            </span>
                        ) : (
                            <span className={iconContainerStyleVariants[key]}>
                                <Icon path={iconPath} className={menuIcon} />
                            </span>
                        )}
                        <span className={menuItemTitleStyleVariants[key]}>
                            {title}
                        </span>
                    </NavLink>
                </li>
            ))}
        </ul>
    );
};

export default Menu;
