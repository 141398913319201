import { WIDTH } from "../config/mediaQueries.js";
import { AppPlatform } from "../graphql/queries/app.js";
import {
    IMediaItem,
    MediaItemPreview,
    Thumbnail,
} from "../graphql/queries/gallery.js";
import { LangCode } from "../graphql/queries/_common.js";

// TODO: improve findClosesThumbnail logic
export const findClosestThumbnail = (
    thumbnails: Thumbnail[],
    minWidth: number = 1200,
): Thumbnail => {
    const thumbnail = thumbnails.reduce<Thumbnail>((prev, current) => {
        if (
            Math.abs(current.width - minWidth) < Math.abs(prev.width - minWidth)
        ) {
            return current;
        } else {
            return prev;
        }
    }, thumbnails[0]);
    return thumbnail;
};

export const formatDate = (date: string, lang: LangCode) =>
    new Date(date).toLocaleString(lang, {
        month: "short",
        day: "numeric",
        year: "numeric",
    });

export const makeIconUrl = (url: string, sizes: string[]): string =>
    `${url}${sizes
        .slice()
        .reverse()
        .find(size => Number(size.slice(1)) > 120)}`;

export const getDevicePlatform = (): AppPlatform | null => {
    try {
        if (typeof window !== "undefined" && typeof navigator !== "undefined") {
            const userAgent =
                navigator.userAgent || navigator.vendor || navigator.platform;

            if (/android/i.test(userAgent)) {
                return "android";
            }

            if (/iPad|iPhone|iPod/.test(userAgent)) {
                return "ios";
            }

            return null;
        }
        return null;
    } catch (e) {
        return null;
    }
};

interface SrsSetSizes {
    phone?: number;
    largePhone?: number;
    tablet?: number;
    laptop?: number;
    desktop?: number;
}

export interface ResponsiveImgSrc {
    srcSet: string;
    sizes: string;
}

const makeBreakpoint = (breakpoint: number, width?: number): string =>
    width ? `(min-width: ${breakpoint}px) ${width}px, ` : "";

export const makeResponsiveSrc = (
    sourceUrl: string,
    thumbnails: Thumbnail[],
    { phone, largePhone, tablet, laptop, desktop }: SrsSetSizes,
): ResponsiveImgSrc => {
    const sizes = [
        makeBreakpoint(WIDTH.desktop, desktop),
        makeBreakpoint(WIDTH.laptop, laptop),
        makeBreakpoint(WIDTH.tablet, tablet),
        makeBreakpoint(WIDTH.largePhone, largePhone),
        phone ? `${phone}px` : `100vw`,
    ].join("");
    return {
        sizes,
        srcSet: thumbnails
            .map(
                (size: Thumbnail) =>
                    `${sourceUrl}${size.fileName} ${size.width}w`,
            )
            .join(", "),
    };
};

export const isClient = (): boolean => {
    if (typeof window !== "undefined") {
        return true;
    }
    return false;
};
export const getLocalStorageItem = (item: string) => {
    try {
        return localStorage.getItem(item);
    } catch (error) {
        return null;
    }
};

export const setLocalStorageItem = (item: string, key: string) => {
    try {
        localStorage.setItem(item, key);
        return null;
    } catch (error) {
        return null;
    }
};

export const trackMarketUrl = (
    baseUrl: string,
    platform: AppPlatform,
    location: string,
    item: string,
): string => {
    const url = new URL(baseUrl);
    if (platform === "android") {
        url.searchParams.set(
            "referrer",
            `utm_source=vitosite&utm_medium=${item}&utm_campaign=${location}`,
        );
    }
    if (platform === "ios") {
        url.pathname = `${url.pathname}`;
        url.searchParams.set("mt", "8"); // mt equals 8 means that it is mobile application
        url.searchParams.set("pt", "19853"); // 19853 - it is Vito Technology's AppStore ID it is forever constant
        url.searchParams.set("ct", `vitosite/${location}:${item}`);
    }
    // TODO: research url structure
    // if (platform === "huawei") {
    //     url.searchParams.set("campaign_channel", "vitosite");
    //     url.searchParams.set("campaign_medium", `${item}`);
    //     url.searchParams.set("campaign_name", `${location}`);
    // }
    return url.toString();
};

interface LinkData {
    linkHref: string;
    external?: boolean;
    download?: boolean;
}

export const normalizeLinkHref = (href: string): LinkData => {
    try {
        const { hostname, pathname } = new URL(href);
        const regex = /(.zip|.rar|.pdf)$/i;
        const download = regex.test(href) || undefined;
        if (hostname !== "vitotechnology.com" || download) {
            return { linkHref: href, external: true, download };
        }
        return { linkHref: pathname };
    } catch {
        return { linkHref: href };
    }
};
export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

// TODO: improve makeImgUrl with finding the most suitable thumbnail
export const makeBackgroundImgUrl = (
    mediaItem: MediaItemPreview | IMediaItem | null | undefined,
): string | undefined => {
    if (!mediaItem) return undefined;
    if (mediaItem.__typename === "Image") {
        return (
            mediaItem.content.sourceUrl +
            mediaItem.content.thumbnails[0].fileName
        );
    }
};

export const abbrevieteNumber = (value: number): string => {
    const suffixes = ["", "K", "M", "G", "T", "P", "E"];
    if (value < 1000) {
        return `${value}`;
    }
    let newValue = value;
    let suffixIndex = 0;
    while (newValue >= 1000) {
        newValue /= 1000;
        suffixIndex++;
    }
    const fixed = newValue.toFixed(1);
    const floored = Math.floor(newValue);
    const final =
        `${floored}`.length <= 1 && Number(fixed) > floored ? fixed : floored;
    return `${final}${suffixes[suffixIndex]}`;
};
