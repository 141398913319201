import React from "react";
import { Helmet } from "react-helmet";
import { COMPANY_NAME } from "../../../_common/config.js";
import { ASSETS_PATH } from "../../config/content.js";
import { Faq } from "../../graphql/queries/products.js";

interface NewsStructuredMetadata {
    type: "News";
    title: string;
    description: string;
    pageUrl: string;
    imageUrl: string;
    datePublished: string;
    dateModified: string;
    authorType: "Person" | "Organization";
    authorName: string;
}
interface FaqStructuredMetadata {
    type: "FAQ";
    faq: Faq[];
}

// interface AppStructuredMetadata {
//     type: "App";
//     title: string;
//     ratingValue: string;
//     ratingCount: string;
//     bestRating: string;
//     operatingSystem: string;
// }
interface Props {
    data: NewsStructuredMetadata | FaqStructuredMetadata;
    // | AppStructuredMetadata;
}
const getData = (
    data: NewsStructuredMetadata | FaqStructuredMetadata,
    // | AppStructuredMetadata
) => {
    switch (data.type) {
        case "News":
            return {
                "@context": "https://schema.org",
                "@type": "Article",
                mainEntityOfPage: {
                    "@type": "WebPage",
                    "@id": data.pageUrl,
                },
                headline: data.title,
                image: [data.imageUrl],
                datePublished: data.datePublished,
                dateModified: data.dateModified,
                author: {
                    "@type": data.authorType,
                    name: data.authorName,
                },
                publisher: {
                    "@type": "Organization",
                    name: COMPANY_NAME,
                    logo: {
                        "@type": "ImageObject",
                        url: `${ASSETS_PATH}/icons/icon-512x512.png`,
                        width: 512,
                        height: 512,
                    },
                },
                description: data.description,
            };
        case "FAQ":
            const mainEntityOfFaq = data.faq.map(({ question, answer }) => ({
                "@type": "Question",
                name: question,
                acceptedAnswer: {
                    "@type": "Answer",
                    text: answer,
                },
            }));
            return {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                mainEntity: mainEntityOfFaq,
            };

        // case "App":
        //     return {
        //         "@context": "https://schema.org",
        //         "@type": "SoftwareApplication",
        //         name: data.title,
        //         operatingSystem: data.operatingSystem,
        //         applicationCategory: "EducationalApplication",
        //         aggregateRating: {
        //             "@type": "AggregateRating",
        //             ratingValue: data.ratingValue,
        //             ratingCount: data.ratingCount,
        //             bestRating: data.bestRating,
        //         },
        //     };
    }
};
const HtmlGoogleStructuredData: React.FC<Props> = ({ data }) => {
    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(getData(data))}
            </script>
        </Helmet>
    );
};

export default HtmlGoogleStructuredData;
