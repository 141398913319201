import React from "react";
import { frame, imgPlaceholder } from "./VideoFrame.css.js";

interface Props {
    aspectRatio: string;
    showPlaceholder?: boolean;
    src: string;
}

const VideoFrame: React.FC<Props> = ({
    aspectRatio,
    showPlaceholder = true,
    src,
}) => {
    const [width, height] = aspectRatio.split(":").map(size => Number(size));
    const padding = (height / width) * 100;

    return (
        <div style={{ paddingBottom: `${padding}%` }}>
            {showPlaceholder && <div className={imgPlaceholder} />}
            <iframe
                className={frame}
                width="100%"
                height="100%"
                src={src}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                id="video"
            />
        </div>
    );
};

export default VideoFrame;
