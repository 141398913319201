import React from "react";
import VitoLogo from "../svg/VitoLogo.js";
import { imageContainer, imagePlaceholder, img } from "./ImageLoader.css.js";

interface Props {
    aspectRatio: string;
    showPlaceholder?: boolean;
    src: string;
    srcSet?: string;
    sizes?: string;
    alt?: string;
}

const ImageLoader: React.FC<Props> = ({
    aspectRatio,
    src,
    alt,
    srcSet,
    sizes,
    showPlaceholder = true,
}) => {
    const [width, height] = aspectRatio.split(":").map(size => Number(size));
    const padding = (height / width) * 100;

    return (
        <div
            style={{ paddingBottom: `${padding}%` }}
            className={imageContainer}
        >
            {showPlaceholder && (
                <div className={imagePlaceholder}>
                    <VitoLogo width={"20%"} colored={false} />
                </div>
            )}
            <img
                className={img}
                src={src}
                alt={alt}
                srcSet={srcSet}
                sizes={sizes}
            />
        </div>
    );
};

export default ImageLoader;
