import React from "react";
import { Link } from "react-router-dom";

import { ImagePreview } from "../../graphql/queries/gallery.js";
import ImageItem, { SrcSetSizes } from "../common/ImageItem.js";
import {
    imageWrapper,
    itemWrapper,
    newsDate,
    newsLink,
    newsTitle,
} from "./NewsItem.css.js";

interface Props {
    image: ImagePreview;
    srcSetSizes: SrcSetSizes;
    date: string;
    title: string;
    link: string;
    isNewsFeed?: boolean;
}
const NewsItem: React.FC<Props> = ({
    image,
    srcSetSizes,
    date,
    title,
    link,
    isNewsFeed,
}) => {
    return (
        <div className={itemWrapper}>
            <div className={imageWrapper}>
                <ImageItem
                    image={image}
                    srcSetSizes={srcSetSizes}
                    aspectRatio="16:9"
                />
            </div>
            <time className={newsDate}>{date}</time>
            <Link to={link} className={newsLink}>
                {isNewsFeed ? (
                    <h2 className={newsTitle}>{title}</h2>
                ) : (
                    <h3 className={newsTitle}>{title}</h3>
                )}
            </Link>
        </div>
    );
};

export default NewsItem;
