import React from "react";
import { Link } from "react-router-dom";

import { Product } from "../../i18n/content.js";
import ImageItem from "../common/ImageItem.js";
import MarkdownRenderer from "../common/MarkdownRenderer.js";
import {
    appLink,
    appWrapper,
    cardDescription,
    cardTitle,
    featuredAppWrapper,
    featuredTextBox,
    imgLink,
    textBox,
} from "./ProductCard.css.js";

interface Props {
    appButton: string;
    url: string;
}
const ProductCard: React.FC<Props & Product> = ({
    featured,
    image,
    title,
    description,
    appButton,
    url,
}) => {
    return (
        <div className={featured ? featuredAppWrapper : appWrapper}>
            <Link className={imgLink} to={url}>
                <ImageItem
                    image={image}
                    srcSetSizes={
                        featured
                            ? {
                                  desktop: 822,
                                  laptop: 607,
                                  tablet: 536,
                              }
                            : {
                                  desktop: 569,
                                  laptop: 419,
                                  tablet: 253,
                              }
                    }
                />
            </Link>

            <div className={featured ? featuredTextBox : textBox}>
                <h2 className={cardTitle}>{title}</h2>
                <MarkdownRenderer
                    source={description}
                    className={cardDescription}
                />

                <Link className={appLink} to={url}>
                    {appButton}
                </Link>
            </div>
        </div>
    );
};

export default ProductCard;
