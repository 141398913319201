import React from "react";
import { Helmet } from "react-helmet";

interface Props {
    domainURL: string;
    path: string;
}

export const HtmlAlternateLinks: React.FC<Props> = ({ domainURL, path }) => {
    return (
        <Helmet>
            <link
                rel="alternate"
                hrefLang="x-default"
                href={`${domainURL}${path}`}
            />
        </Helmet>
    );
};

export default HtmlAlternateLinks;
