import React, { useContext } from "react";
import { withRouter, RouteComponentProps, useLocation } from "react-router";
import { Link } from "react-router-dom";

import HtmlTitle from "./helmet/HtmlTitle.js";
import { container } from "./styles/common.css.js";
import Content from "../i18n/content.js";
import { gaNavClick } from "../helpers/googleAnalytics.js";
import {
    errorLayout,
    errorMessage,
    errorTitle,
    link,
    linksWrapper,
} from "./ErrorMsg.css.js";

export interface Props {
    type: "notFound" | "default";
}

const ErrorMsg: React.FC<Props & RouteComponentProps> = ({
    type,
    staticContext,
}) => {
    const {
        common: {
            errors,
            layout: {
                menu: { home, products, news, aboutUs, support, contactUs },
            },
        },
    } = useContext(Content);
    const { pathname } = useLocation();

    if (staticContext) {
        staticContext.statusCode = type === "notFound" ? 404 : 500;
    }
    const links = [
        {
            key: "home",
            path: "",
            text: home,
        },
        {
            key: "apps",
            path: "/apps",
            text: products,
        },
        {
            key: "news",
            path: "/news",
            text: news,
        },
        {
            key: "about",
            path: "/about",
            text: aboutUs,
        },
        {
            key: "support",
            path: "/support",
            text: support,
        },
        {
            key: "contacts",
            path: "/contacts",
            text: contactUs,
        },
    ];
    const { title, message } = errors[type];
    return (
        <>
            <HtmlTitle value={title} />
            <div className={container.flex}>
                <div className={errorLayout}>
                    <h1 className={errorTitle}>{title}</h1>
                    <h2 className={errorMessage}>{message}</h2>
                    {type === "notFound" ? (
                        <div className={linksWrapper}>
                            {links.map(({ key, path, text }) => (
                                <Link
                                    key={key}
                                    to={path}
                                    onClick={() => {
                                        gaNavClick(key, "page404", pathname);
                                    }}
                                    className={link}
                                >
                                    {text}
                                </Link>
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default withRouter(ErrorMsg);
