import React from "react";
import { Helmet } from "react-helmet";

import { LangCode } from "../../graphql/queries/_common.js";

interface Props {
    lang: LangCode;
}

const HtmlLang: React.FC<Props> = ({ lang }) => (
    <Helmet
        htmlAttributes={{
            lang,
        }}
    />
);

export default HtmlLang;
