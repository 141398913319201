import React, { useContext } from "react";
import { Link } from "react-router-dom";

import Content, { Links } from "../../i18n/content.js";
import { RelatedNews, ThirdPartyNews } from "../../graphql/queries/products.js";
import LinkItem from "../common/LinkItem.js";
import { commonSectionTitle, container } from "../styles/common.css.js";
import {
    buttonBox,
    buttonsBox,
    infoBox,
    infoBoxCol,
    newsDate,
    newsDescription,
    newsImg,
    subTitle,
} from "./MoreInfo.css.js";

interface Props {
    links: Links[];
    relatedNews: RelatedNews[];
    thirdPartyNews: ThirdPartyNews[];
    productId: string;
}
const MoreInfo: React.FC<Props> = ({
    links,
    relatedNews,
    thirdPartyNews,
    productId,
}) => {
    const {
        productPage: {
            moreInfoSection: { title, newsTitle, recourceTitle },
        },
    } = useContext(Content);

    const supportLink = {
        text: "support",
        url: `/support/${productId}`,
    };
    const linkList = [...links, supportLink];

    return (
        <div className={container.flex800}>
            <h2 className={commonSectionTitle}>{title}</h2>
            <div className={infoBox}>
                {relatedNews ? (
                    <div className={infoBoxCol}>
                        <h3 className={subTitle}>{newsTitle}</h3>
                        {relatedNews.map(
                            ({ id, date, title, mediaItem: { sourceUrl } }) => (
                                <div key={id}>
                                    <img className={newsImg} src={sourceUrl} />
                                    <div className={newsDescription}>
                                        <div className={newsDate}>{date}</div>
                                        <Link to={`/news${id}`}>{title}</Link>
                                    </div>
                                </div>
                            ),
                        )}
                    </div>
                ) : null}
                {thirdPartyNews ? (
                    <div className={infoBoxCol}>
                        <h3 className={subTitle}>{recourceTitle}</h3>
                        {thirdPartyNews.map(({ id, date, title, url }) => (
                            <div key={id}>
                                <div>
                                    <div className={newsDate}>{date}</div>
                                    <Link to={url}>{title}</Link>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
            <div className={buttonsBox}>
                {linkList.map(({ text, url }) => {
                    return (
                        <div className={buttonBox} key={url}>
                            <LinkItem href={url}>{text}</LinkItem>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default MoreInfo;
