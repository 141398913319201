import React from "react";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import {
    OPEN_GRAPH_IMAGE_PATH,
    SOCIAL_MEDIA_LINKS,
} from "../../config/content.js";
import { MAP_MARKER } from "../../config/icons.js";
import {
    GetContactsContentData,
    GET_CONTACTS_CONTENT,
} from "../../graphql/queries/contacts.js";
import { ContactsPageContent } from "../../i18n/content.js";
import MarkdownRenderer from "../common/MarkdownRenderer.js";
import ErrorMsg from "../ErrorMsg.js";
import MetaTags from "../MetaTags.js";
import SocialMediaLink from "../SocialMediaLink.js";
import Spinner from "../Spinner.js";
import Icon from "../svg/Icon.js";
import { commonPageTitle, container } from "../styles/common.css.js";
import {
    contacts,
    contactTitle,
    locationIcon,
    locationItem,
    locationItemsWrapper,
    locations,
    mail,
    pageSubtitle,
    socialLinks,
    contactsText,
} from "./Contacts.css.js";

interface Props {
    path: string;
}
const ContactUs: React.FC<Props> = ({ path }) => {
    const { data, loading, error } =
        useQuery<GetContactsContentData<ContactsPageContent>>(
            GET_CONTACTS_CONTENT,
        );
    if (error) {
        return <ErrorMsg type={"default"} />;
    }

    if (loading) {
        return <Spinner />;
    }

    if (data && data.staticContent) {
        const {
            content: {
                pageTitle,
                metaTitle,
                metaDescription,
                contactsTitle,
                locationTitle,
                socialTitle,
                marketingContact,
                supportContact,
                devsContact,
                addressOne,
                addressTwo,
            },
        } = data.staticContent;
        const contactItems = [supportContact, marketingContact, devsContact];
        const locationItems = [addressOne, addressTwo];
        return (
            <>
                <MetaTags
                    path={path}
                    title={metaTitle || pageTitle}
                    description={metaDescription}
                    openGraphImage={[OPEN_GRAPH_IMAGE_PATH, "1200x630"]}
                />
                <div className={container.flex800}>
                    <h1 className={commonPageTitle}>{pageTitle}</h1>
                    <div className={contacts}>
                        <h2 className={pageSubtitle}>{contactsTitle}</h2>
                        {contactItems.map(
                            ({ title, aboutText, mailText, mailUrl }) => (
                                <div key={mailUrl}>
                                    <h3 className={contactTitle}>{title}</h3>
                                    <p className={contactsText}>{aboutText}</p>
                                    <a className={mail} href={mailUrl}>
                                        {mailText}
                                    </a>
                                </div>
                            ),
                        )}
                    </div>
                    <div className={locations}>
                        <h2 className={pageSubtitle}>{locationTitle}</h2>
                        <div className={locationItemsWrapper}>
                            {locationItems.map(item => (
                                <div className={locationItem} key={item}>
                                    <Icon
                                        path={MAP_MARKER}
                                        className={locationIcon}
                                        height="auto"
                                    />
                                    <div>
                                        <MarkdownRenderer source={item} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <h2 className={pageSubtitle}>{socialTitle}</h2>
                        <div className={socialLinks}>
                            {SOCIAL_MEDIA_LINKS.map(({ iconKey, link }) => (
                                <SocialMediaLink
                                    key={iconKey}
                                    iconKey={iconKey}
                                    link={link}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </>
        );
    }
    return <ErrorMsg type={"notFound"} />;
};

export default ContactUs;
