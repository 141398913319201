import React from "react";
import ReactMarkdown from "react-markdown";
import { IMediaItem } from "../../graphql/queries/gallery.js";
import ImageItem from "../common/ImageItem.js";
import {
    infoDate,
    infoText,
    infoTitle,
    timelineWrapper,
} from "./Timeline.css.js";

interface Props {
    timelineData: string;
    mediaItems: IMediaItem[];
}
const Timeline: React.FC<Props> = ({ timelineData, mediaItems }) => {
    return (
        <div className={timelineWrapper}>
            <ReactMarkdown
                source={timelineData}
                renderers={{
                    heading: props => {
                        if (props.level === 4) {
                            return <h4 className={infoTitle} {...props} />;
                        }
                        return <h3 className={infoDate} {...props} />;
                    },
                    paragraph: props => <p className={infoText} {...props} />,
                    image: props => {
                        const image = mediaItems.find(
                            item => item.id === props.src,
                        );

                        return image?.__typename === "Image" ? (
                            <ImageItem
                                image={image}
                                noAspectWrapper
                                srcSetSizes={{
                                    desktop: 540,
                                    laptop: 540,
                                    tablet: 438,
                                }}
                            />
                        ) : null;
                    },
                }}
            />
        </div>
    );
};

export default Timeline;
