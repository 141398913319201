import React from "react";

import { gaSocialMediaLinkClick } from "../helpers/googleAnalytics.js";
import Icon from "./svg/Icon.js";
import { capitalize } from "../helpers/utils.js";
import socialIcons from "../config/social-icons.js";
import { socialMediaLink } from "./SocialMediaLink.css.js";
import { useLocation } from "react-router";

export interface SocialMediaLinkProps {
    iconKey: string;
    link: string;
}

const SocialMediaLink: React.FC<SocialMediaLinkProps> = ({ iconKey, link }) => {
    const { pathname } = useLocation();

    return (
        <a
            className={socialMediaLink}
            href={link}
            id={`link-social-${iconKey}`}
            aria-label={capitalize(iconKey)}
            onClick={() => {
                gaSocialMediaLinkClick(iconKey, pathname);
            }}
        >
            <Icon
                path={socialIcons[iconKey].path}
                viewBox={socialIcons[iconKey].viewBox}
            />
        </a>
    );
};

export default SocialMediaLink;
