import React, { useContext } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Link, useLocation } from "react-router-dom";
import ConfigContext from "../../config/config-context.js";
import {
    gaConversionLinkClick,
    gaMarkdownLinkClick,
} from "../../helpers/googleAnalytics.js";
import { markdownTableRenderers } from "./markdownUtils.js";
import { markdownDefaultStyle } from "./MarkdownRenderer.css.js";

interface MarkdownLinkProps {
    href: string;
    children: React.ReactNode;
}

const MarkdownLink: React.FC<MarkdownLinkProps> = ({ href, children }) => {
    const _href: string = href as string;
    const { conversionDomains } = useContext(ConfigContext);
    const { pathname } = useLocation();
    if (_href.includes("#")) {
        return (
            <a
                href={_href}
                onClick={() => {
                    gaMarkdownLinkClick("hash", _href, pathname);
                }}
            >
                {children}
            </a>
        );
    }

    let path = "";
    try {
        const { hostname, pathname } = new URL(_href);
        if (hostname !== "vitotechnology.com") {
            return (
                <a
                    href={_href}
                    onClick={() => {
                        conversionDomains.includes(hostname)
                            ? gaConversionLinkClick(
                                  "in_article_conversion_link",
                                  pathname,
                                  _href,
                              )
                            : gaMarkdownLinkClick("external", _href, pathname);
                    }}
                >
                    {children}
                </a>
            );
        }
        path = pathname;
    } catch {
        path = _href;
    }
    const to = path;

    // TODO: check links with search params from markdown source
    return (
        <Link
            to={to}
            onClick={() => {
                gaMarkdownLinkClick("internal", to, pathname);
            }}
        >
            {children}
        </Link>
    );
};

interface Props {
    source: string;
    className?: string;
}

const MarkdownRenderer: React.FC<Props> = ({ source, className }) => {
    const renderers = {
        link: MarkdownLink,
        ...markdownTableRenderers,
    };
    return (
        <Markdown
            className={`${markdownDefaultStyle} ${className}`}
            source={source}
            skipHtml={true}
            transformLinkUri={uri => {
                if (uri && uri.startsWith("self:")) {
                    return "#";
                }
                return uri;
            }}
            renderers={renderers}
            plugins={[remarkGfm]}
        />
    );
};
export default MarkdownRenderer;
