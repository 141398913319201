import React from "react";
import { Helmet } from "react-helmet";
import { COMPANY_NAME } from "../../../_common/config.js";
import { theme } from "../styles/theme.css.js";
import { ASSETS_PATH } from "../../config/content.js";

const HtmlInit: React.FC = () => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
            <meta name="google" content="notranslate" />
            <link rel="shortcut icon" href="/favicon.ico" />
            <link rel="manifest" href="/manifest.json" />
            <meta name="theme-color" content={theme.colors.background} />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta
                name="apple-mobile-web-app-status-bar-style"
                content="default"
            />
            <title>{COMPANY_NAME}</title>
            {[
                {
                    file: "touch-icon-180x180.png",
                    size: "180x180",
                },
                {
                    file: "touch-icon-192x192.png",
                    size: "192x192",
                },
            ].map(({ file, size }) => (
                <link
                    key={file}
                    rel="apple-touch-icon"
                    href={`${ASSETS_PATH}/icons/${file}`}
                    sizes={size}
                />
            ))}
        </Helmet>
    );
};

export default HtmlInit;
