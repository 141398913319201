import React, { useContext } from "react";
import Content from "../../i18n/content.js";

import { commonSectionTitle } from "../styles/common.css.js";
import { ReviewSlideContent } from "../../graphql/queries/products.js";
import ReviewsSlider from "../slider/ReviewsSlider.js";
import { wrapper } from "./Reviews.css.js";

interface Props {
    reviews: ReviewSlideContent[];
}
const Reviews: React.FC<Props> = ({ reviews }) => {
    const {
        productPage: {
            assessmentSection: { title },
        },
    } = useContext(Content);
    return (
        <div className={wrapper}>
            <h2 className={commonSectionTitle}>{title}</h2>
            <ReviewsSlider slides={reviews} />
        </div>
    );
};

export default Reviews;
