import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import ConfigContext from "../../config/config-context.js";

const HtmlFacebook: React.FC = () => {
    const { facebookPixelId } = useContext(ConfigContext);
    if (!facebookPixelId) return null;

    return (
        <Helmet>
            <meta property="fb:app_id" content={facebookPixelId} />
        </Helmet>
    );
};

export default HtmlFacebook;
