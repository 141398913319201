import React from "react";
import { FieldRenderProps } from "react-final-form";
import { errorMessage, optionItem, selectField } from "./formComponents.css.js";

type Props = FieldRenderProps<string, any>;

const SelectInput: React.FC<Props> = ({
    optionsData,
    input,
    meta,
    ...rest
}: Props) => (
    <>
        <select className={selectField} {...input} {...rest}>
            {optionsData.map((item: string, index: string) => {
                return (
                    <option className={optionItem} key={index}>
                        {item}
                    </option>
                );
            })}
        </select>
        {meta.error && meta.touched && (
            <span className={errorMessage}>{meta.error}</span>
        )}
    </>
);

export default SelectInput;
