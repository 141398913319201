import React from "react";
import { Link } from "react-router-dom";

import { anchorButton } from "../styles/common.css.js";
import { aboutText, aboutWrapper } from "./About.css.js";

interface Props {
    description: string;
    aboutButton: string;
}
const About: React.FC<Props> = ({ description, aboutButton }) => {
    return (
        <section className={aboutWrapper}>
            <div className={aboutText}>{description}</div>
            <Link to={"/about"} className={anchorButton}>
                {aboutButton}
            </Link>
        </section>
    );
};

export default About;
