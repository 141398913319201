import React from "react";
import { FieldRenderProps } from "react-final-form";
import { errorMessage, textareaField } from "./formComponents.css.js";

type Props = FieldRenderProps<string, any>;

const TextAreaInput: React.FC<Props> = ({ input, meta, ...rest }: Props) => (
    <>
        <textarea {...input} {...rest} className={textareaField} />
        {meta.error && meta.touched && (
            <span className={errorMessage}>{meta.error}</span>
        )}
    </>
);

export default TextAreaInput;
