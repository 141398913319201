import gql from "graphql-tag";
import { SocialMedia } from "./_common.js";

export type NodeWithEngagementInfoTypename = "CompanyNews";

export interface IncrementEngagementCounterVariables {
    input: {
        socialMedia: SocialMedia;
        typename: NodeWithEngagementInfoTypename;
        id: string;
    };
}

export const INCREMENT_ENGAGEMENT_COUNTER = gql`
    mutation incrementEngagementCounter(
        $input: IncrementEngagementCounterInput!
    ) {
        incrementEngagementCounter(input: $input) {
            node {
                id
            }
        }
    }
`;
