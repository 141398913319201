import React from "react";
import { WIDTH } from "../../config/mediaQueries.js";
import { ImagePreview, Image } from "../../graphql/queries/gallery.js";
import ImageLoader from "./ImageLoader.js";

interface Props {
    image: Image | ImagePreview | null | undefined;
    srcSetSizes: SrcSetSizes;
    alt?: string;
    showPlaceholder?: boolean;
    aspectRatio?: string;
    noAspectWrapper?: boolean;
}

export interface SrcSetSizes {
    phone?: number;
    largePhone?: number;
    tablet?: number;
    laptop?: number;
    desktop?: number;
}

const makeBreakpoint = (breakpoint: number, width?: number): string =>
    width ? `(min-width: ${breakpoint}px) ${width}px, ` : "";

const ImageItem: React.FC<Props> = ({
    image,
    srcSetSizes,
    alt,
    showPlaceholder,
    aspectRatio,
    noAspectWrapper,
}) => {
    if (!image) return null;

    const { name, sourceUrl, thumbnails } = image.content;

    if (!thumbnails[0]) return null;

    const { width, height } = thumbnails[0];

    const src = `${sourceUrl}${thumbnails[0].fileName}`;

    const { desktop, laptop, tablet, largePhone, phone } = srcSetSizes;

    const sizes = [
        makeBreakpoint(WIDTH.desktop, desktop),
        makeBreakpoint(WIDTH.laptop, laptop),
        makeBreakpoint(WIDTH.tablet, tablet),
        makeBreakpoint(WIDTH.largePhone, largePhone),
        phone ? `${phone}px` : `100vw`,
    ].join("");

    const srcSet = thumbnails
        .map(
            thumbnail =>
                `${sourceUrl}${thumbnail.fileName} ${thumbnail.width}w`,
        )
        .join(", ");

    if (noAspectWrapper) {
        return (
            <img
                src={src}
                style={{
                    aspectRatio: `${width} / ${height}`,
                    width: "100%",
                }}
                srcSet={srcSet}
                sizes={sizes}
                alt={name || alt || undefined}
            />
        );
    }
    return (
        <ImageLoader
            src={src}
            aspectRatio={aspectRatio || `${width}:${height}`}
            srcSet={srcSet}
            sizes={sizes}
            showPlaceholder={showPlaceholder}
            alt={alt || name || undefined}
        />
    );
};

export default ImageItem;
