import React from "react";
import { abbrevieteNumber } from "../../helpers/utils.js";
import { badgeContainer } from "./SharingCounterBadge.css.js";
interface Props {
    count: number | null;
}
const SharingCounterBadge: React.FC<Props> = ({ count }) => {
    if (!count) return null;

    const abbrevieteCountValue = abbrevieteNumber(count);
    const countLength = abbrevieteCountValue.length;

    const rectWidth = (countLength: number) => {
        if (countLength === 1) {
            return 10;
        } else if (countLength === 2) {
            return 16;
        } else if (countLength === 3) {
            return 20;
        } else if (countLength >= 4) {
            return 23;
        }
        return 0;
    };

    return (
        <div className={`sharing-counter-badge-container ${badgeContainer}`}>
            <svg
                width={rectWidth(countLength)}
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <rect
                    x="0"
                    y="0"
                    rx="5"
                    ry="5"
                    height="100%"
                    width="100%"
                    fill="#E8E8E8"
                />
                <text
                    x="50%"
                    y="50%"
                    fill="#000"
                    fontSize="8"
                    fontWeight="500"
                    textAnchor="middle"
                    dominantBaseline="central"
                >
                    {abbrevieteCountValue}
                </text>
            </svg>
        </div>
    );
};

export default SharingCounterBadge;
