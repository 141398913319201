import gql from "graphql-tag";
import { IMediaItem } from "./gallery.js";

export interface GetStaticContentVariables {
    id: string;
}

export interface GetAboutContentData<T> {
    staticContent: {
        id: string;
        content: T;
        mediaItems: IMediaItem[];
    };
}

export const GET_ABOUT_CONTENT = gql`
    query getAboutContent {
        staticContent(id: "vito-site-about-page") {
            id
            content
            mediaItems {
                id
                content {
                    name
                    sourceUrl
                    ... on ImageContent {
                        thumbnails {
                            fileName
                            width
                            height
                        }
                    }
                }
            }
        }
    }
`;
