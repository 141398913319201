import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import ConfigContext from "../../config/config-context.js";
import { isDev } from "../../config/env.js";

export const HtmlAnalytics: React.FC = () => {
    const { googleAnalyticsId } = useContext(ConfigContext);
    if (!googleAnalyticsId) return null;

    const debugMode = isDev ? `,'debug_mode':true` : "";

    const gtagSnippet = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${googleAnalyticsId}', {
        'send_page_view': false ${debugMode}
    });`;

    return (
        <Helmet>
            <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
            />
            <script>{gtagSnippet}</script>
        </Helmet>
    );
};

export default HtmlAnalytics;
