import React, { useContext, useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client/react/hooks/useApolloClient.js";

import { getDevicePlatform } from "../../helpers/utils.js";
import { EngagementInfo, SocialMedia } from "../../graphql/queries/_common.js";
import { gaShareClick } from "../../helpers/googleAnalytics.js";
import {
    INCREMENT_ENGAGEMENT_COUNTER,
    IncrementEngagementCounterVariables,
    NodeWithEngagementInfoTypename,
} from "../../graphql/queries/engagement-info.js";
import Content from "../../i18n/content.js";
import { DOMAIN_URL } from "../../config/env.js";
import SocialMediaShareLink from "./SocialMediaShareLink.js";
import {
    buttonsContainer,
    buttonsTitle,
    sharingContainer,
} from "./ShareButtons.css.js";
import { useLocation } from "react-router";

const buildLinkHref = (
    socialMedia: SocialMedia,
    encodedUrl: string,
    encodedPageTitle: string,
    tags?: string[],
) => {
    switch (socialMedia) {
        case "facebook":
            return `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&quote=${encodedPageTitle}${
                tags && tags.length > 0 ? `&hashtag=%23${tags[0]}` : ""
            }`;
        case "messenger":
            return `fb-messenger://share/?link=${encodedUrl}`;
        case "twitter":
            return `https://twitter.com/share?text=${encodedPageTitle}${
                tags && tags.length > 0 ? `&hashtags=${tags.join(",")}` : ""
            }&url=${encodedUrl}`;
        case "telegram":
            return `https://telegram.me/share/?url=${encodedUrl}&text=${encodedPageTitle}`;
        case "whatsapp":
            return `https://${
                getDevicePlatform() ? "wa.me/" : "web.whatsapp.com/send"
            }?text=${encodedUrl}`;
        default:
            return undefined;
    }
};

const openNativeSharing = async (data: ShareData) => {
    try {
        if (
            typeof navigator === "object" &&
            typeof navigator.share === "function"
        ) {
            await navigator.share(data);
        }
    } catch (e) {
        console.error(e);
    }
};

const isNativeSharingEnabled = (): boolean => {
    return (
        typeof window !== "undefined" &&
        typeof navigator === "object" &&
        typeof navigator.share === "function"
    );
};

interface Props {
    pagePath: string;
    pageTitle: string;
    position: "top" | "bottom";
    typename: NodeWithEngagementInfoTypename;
    medium?: string;
    label?: string;
    engagementInfo: EngagementInfo;
    title?: string;
    tags?: string[];
    // slug?: string | null;
}

const ShareButtons: React.FC<Props> = ({
    pagePath,
    pageTitle,
    position,
    typename,
    medium = "referrer",
    label,
    engagementInfo,
    title,
    tags,
    // slug,
}) => {
    const [isFirstRender, switchFirstRender] = useState(true);

    const { langCode } = useContext(Content);
    const apolloClient = useApolloClient();
    const pageUrl = `${DOMAIN_URL}/${langCode}${pagePath}`;
    const { pathname } = useLocation();

    // NOTE: that is not the best way of dealing with SSR mismatch, we need to find more neat way
    useEffect(() => {
        if (isFirstRender) {
            switchFirstRender(false);
        }
    }, []);
    if (isFirstRender) {
        return <div data-nosnippet className={sharingContainer} />;
    }

    const incrementSharingCounter = async (
        id: string,
        socialMedia: SocialMedia,
    ) => {
        await apolloClient.mutate<any, IncrementEngagementCounterVariables>({
            mutation: INCREMENT_ENGAGEMENT_COUNTER,
            variables: {
                input: {
                    id,
                    typename,
                    socialMedia,
                },
            },
        });
    };
    const socialMedias: SocialMedia[] = [
        "facebook",
        "messenger",
        "twitter",
        "telegram",
        "whatsapp",
        "native",
    ];
    return (
        <div className={sharingContainer} data-nosnippet>
            {title && <div className={buttonsTitle}>{title}</div>}
            <div className={buttonsContainer}>
                {socialMedias.map(socialMedia => {
                    const trackedPageUrl =
                        // slug ? `${DOMAIN_URL}/${socialMediaTagsMap[socialMedia]}/${langCode}/${slug}`:
                        `${pageUrl}?utm_source=${socialMedia}&utm_medium=${typename.toLowerCase()}&utm_campaign=share`;

                    let onClick = (incrementOnServer: boolean) => {
                        gaShareClick(
                            socialMedia,
                            label || medium,
                            position,
                            pathname,
                        );
                        if (incrementOnServer) {
                            incrementSharingCounter(medium, socialMedia);
                        }
                    };

                    if (socialMedia === "native" && !isNativeSharingEnabled()) {
                        return null;
                    }
                    if (socialMedia === "native" && isNativeSharingEnabled()) {
                        onClick = (incrementOnServer: boolean) => {
                            gaShareClick(
                                socialMedia,
                                label || medium,
                                position,
                                pathname,
                            );
                            if (incrementOnServer) {
                                incrementSharingCounter(medium, socialMedia);
                            }

                            openNativeSharing({
                                url: trackedPageUrl,
                                title: pageTitle,
                            });
                        };
                    }
                    const counterKey = `${socialMedia}_${typename}_${medium}`;
                    const props = {
                        key: socialMedia,
                        counterKey: counterKey,
                        socialMedia,
                        id: `${socialMedia}-share-${position}-link`,
                        count: engagementInfo[socialMedia],
                        onClick,
                        href: buildLinkHref(
                            socialMedia,
                            encodeURIComponent(trackedPageUrl),
                            encodeURIComponent(pageTitle),
                            tags,
                        ),
                    };
                    return <SocialMediaShareLink {...props} />;
                })}
            </div>
        </div>
    );
};

export default ShareButtons;
