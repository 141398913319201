import React from "react";
import { GetCommonContentData } from "../graphql/queries/common-content.js";
import { ImagePreview, Image } from "../graphql/queries/gallery.js";
import { LangCode } from "../graphql/queries/_common.js";

export interface LocalizedContent {
    langCode: LangCode;
    common: CommonContent;
    productsPage: ProductsPageContent;
    productPage: ProductPageContent;
    newsFeedPage: NewsFeedPageContent;
    newsPage: NewsPageContent;
    supportPage: SupportPageContent;
}
export interface MenuItemsMap<T> {
    [key: string]: T;
    home: T;
    apps: T;
    news: T;
    about: T;
    support: T;
    contacts: T;
}

interface ErrorContent {
    title: string;
    message: string;
}

export interface CommonContent {
    layout: {
        menu: {
            home: string;
            app: string;
            news: string;
            products: string;
            aboutUs: string;
            support: string;
            contactUs: string;
        };
        footer: {
            terms: string;
            privacy: string;
            contact: string;
            followUs: string;
            siteMap: string;
        };
    };
    cookieConsent: {
        text: string;
        learnMoreLink: string;
        acceptButton: string;
    };
    errors: {
        default: ErrorContent;
        notFound: ErrorContent;
    };
    storeBadges: {
        ios: string;
        android: string;
        huawei: string;
    };
    actions: {
        getAppButton: string;
        downloadAppButton: string;
    };
}

export interface Links {
    text: string;
    url: string;
}

export interface HomePageContent {
    introSection: {
        title: string;
        backgroundImageId: string;
        backgroundImageUrl: string;
        downloadAppButton: string;
        downloadAppButtonLink: string;
    };
    aboutSection: {
        description: string;
        aboutUsButton: string;
    };
    appsSection: {
        title: string;
        allAppsButton: string;
        readMoreButton: string;
    };
    newsSection: {
        title: string;
        moreNewsButton: string;
    };
    partnersSection: {
        title: string;
    };
    metaTitle: string;
    metaDescription: string;
}

export interface Product {
    featured: boolean;
    image: Image | ImagePreview | null;
    title: string;
    description: string;
}
export interface NewsItem {
    id: string;
    featured: boolean;
    urlImg: string;
    imgCredit: string;
    alt: string;
    date: string;
    title: string;
    description: string;
}
export interface ProductsPageContent {
    metaTitle: string;
    metaDescription: string;
    pageTitle: string;
    pageSubtitle: string;
    appButton: string;
    products: Product[];
}
export interface SupportPageContent {
    metaTitle: string;
    metaDescription: string;
    pageTitle: string;
    pageSubtitle: string;
    pageSecondSubtitle: string;
    supportButton: string;
    productSupport: {
        metaTitle: string;
        metaDescription: string;
        mainSectionTitle: string;
        faqTitle: string;
        contactTitle: string;
        emptyFaqContactTitle: string;
        contactButton: string;
    };
    formTitle: string;
    appInputLabel: string;
    nameInputLabel: string;
    nameInputPlaceholder: string;
    emailInputLabel: string;
    emailInputPlaceholder: string;
    platformSelectLabel: string;
    platformSelectOptions: string[];
    messageInputLabel: string;
    messageInputPlaceholder: string;
    filesInputLabel: string;
    filesInputPlaceholder: string;
    submitButton: string;
    cancelButton: string;
    PopupMessageText: string;
    PopupMessageButton: string;
    errorMessageText: string;
    inputValidationText: string;
    emailValidationText: string;
    captchaValidationText: string;
}
export interface NewsPageContent {
    imageCredit: string;
    textCredit: string;
    backToNewsFeed: string;
}
export interface NewsFeedPageContent {
    title: string;
    metaTitle: string;
    metaDescription: string;
    loadMoreButton: string;
    newsItems: NewsItem[];
}

export interface Awards {
    link: string;
    logoSrc: string;
    alt: string;
}
export interface Review {
    quote: string;
    author: string;
}
export interface News {
    urlImg: string;
    urlArticle: string;
    date: string;
    subtitle: string;
}
export interface Recources {
    date: string;
    subtitle: string;
    urlArticle: string;
}
export interface AppStoreButton {
    urlImg: string;
    urlLink: string;
}
export interface TabContent {
    tabButton: string;
    appLogoUrl: string;
    appLogoAlt: string;
    appName: string;
    appStoreButtons: AppStoreButton[];
}
export interface Button {
    textButton: string;
    urlButton: string;
}
export interface ProductPageContent {
    introSection: {
        urlImg: string;
        title: string;
        slogan: string;
        siteButton: string;
        siteButtonUrl: string;
        videoButton: string;
        downloadButton: string;
    };
    storeSection: {
        title: string;
        tabs: TabContent[];
    };
    aboutSection: {
        title: string;
        text: string;
        description: string;
        videoUrl: string;
    };
    featuresSection: {
        title: string;
        readMoreButton: string;
    };
    howToUseSection: {
        title: string;
        text: string;
    };
    assessmentSection: {
        title: string;
        awards: Awards[];
        reviews: {
            review: Review[];
        };
    };

    moreInfoSection: {
        title: string;
        newsTitle: string;
        recourceTitle: string;
        news: News[];
        recources: Recources[];
        buttons: Button[];
    };
}

interface ContactItem {
    title: string;
    aboutText: string;
    mailText: string;
    mailUrl: string;
}
export interface ContactsPageContent {
    pageTitle: string;
    metaTitle: string;
    metaDescription: string;
    contactsTitle: string;
    locationTitle: string;
    socialTitle: string;
    marketingContact: ContactItem;
    supportContact: ContactItem;
    devsContact: ContactItem;
    addressOne: string;
    addressTwo: string;
}

export const buildContent = (
    langCode: LangCode,
    {
        common,
        productsPage,
        productPage,
        newsFeedPage,
        newsPage,
        supportPage,
    }: GetCommonContentData,
): LocalizedContent => {
    return {
        langCode: langCode,
        common: common.content,
        productPage: productPage.content,
        productsPage: productsPage.content,
        newsFeedPage: newsFeedPage.content,
        newsPage: newsPage.content,
        supportPage: supportPage.content,
    };
};

const Content = React.createContext<LocalizedContent>(undefined!);
export default Content;
