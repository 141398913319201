import React from "react";
import { FieldRenderProps } from "react-final-form";
import { errorMessage, inputField } from "./formComponents.css.js";

type Props = FieldRenderProps<string, any>;

const TextInput: React.FC<Props> = ({ input, meta, ...rest }: Props) => (
    <>
        <input className={inputField} type="text" {...input} {...rest} />
        {meta.error && meta.touched && (
            <span className={errorMessage}>{meta.error}</span>
        )}
    </>
);

export default TextInput;
