import React from "react";
import { label, labelContainer, rect } from "./BurgerMenu.css.js";

interface Props {
    toggleMenu: () => void;
    inputId: string;
}

const BurgerMenu: React.FC<Props> = ({ toggleMenu, inputId }) => {
    return (
        <li className={labelContainer}>
            <label className={label} onClick={toggleMenu} htmlFor={inputId}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="-256 -256 512 512"
                    fill="currentColor"
                    width="1em"
                    height="1em"
                >
                    {["-128", "-8", "-8", "112"].map((y, i) => (
                        <rect
                            key={`${i}${y}`}
                            className={rect}
                            x="-150"
                            y={y}
                            height="16"
                            width="300"
                            rx="8"
                        />
                    ))}
                </svg>
            </label>
        </li>
    );
};

export default BurgerMenu;
