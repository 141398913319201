import React from "react";
import { Link } from "react-router-dom";
import { normalizeLinkHref } from "../../helpers/utils.js";
import { anchorButton } from "../styles/common.css.js";
interface Props {
    href: string;
    children: React.ReactNode;
}

const LinkItem: React.FC<Props> = ({ href, children }) => {
    const { linkHref, external, download } = normalizeLinkHref(href);

    if (external) {
        return (
            <a className={anchorButton} href={linkHref} download={download}>
                {children}
            </a>
        );
    }
    return (
        <Link
            className={anchorButton}
            to={({ search }) => `${linkHref}${search}`}
        >
            {children}
        </Link>
    );
};
export default LinkItem;
