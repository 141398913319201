import React from "react";
import { Link } from "react-router-dom";

import ImageSlider, { ImageSlideContent } from "../slider/ImageSlider.js";

import { anchorButton, homeSectionTitle } from "../styles/common.css.js";
import { appsWrapper } from "./Apps.css.js";

interface Props {
    title: string;
    readMoreButton: string;
    allAppsButton: string;
    slides: ImageSlideContent[];
}
const Apps: React.FC<Props> = ({
    title,
    readMoreButton,
    allAppsButton,
    slides,
}) => {
    return (
        <section className={appsWrapper}>
            <h2 className={homeSectionTitle}>{title}</h2>
            <ImageSlider
                slides={slides}
                delaySec={3}
                button={readMoreButton}
                srcSetSizes={{ desktop: 1168, laptop: 868, tablet: 536 }}
            />
            <Link className={anchorButton} to={`/apps`}>
                {allAppsButton}
            </Link>
        </section>
    );
};

export default Apps;
