import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import React, { useContext, useState } from "react";
import { useParams } from "react-router";

import {
    GetProductForSupportPageData,
    GET_PRODUCT_FOR_SUPPORT_PAGE,
} from "../../graphql/queries/products.js";
import ErrorMsg from "../ErrorMsg.js";
import Spinner from "../Spinner.js";
import AccordionItem from "./AccordionItem.js";
import SupportForm from "./SupportForm.js";
import Content from "../../i18n/content.js";
import MetaTags from "../MetaTags.js";
import {
    findClosestThumbnail,
    makeBackgroundImgUrl,
} from "../../helpers/utils.js";
import { Thumbnail } from "../../graphql/queries/gallery.js";
import HtmlGoogleStructuredData from "../helmet/HtmlGoogleStructuredData.js";
import {
    anchorButton,
    commonSectionTitle,
    container,
    productIntroTitle,
} from "../styles/common.css.js";
import {
    accordionSubtitle,
    contactBox,
    contactsTitle,
    emptyFaqNotice,
    faqBox,
    intro,
} from "./ProductSupport.css.js";
import { theme } from "../styles/theme.css.js";

const ProductSupport: React.FC = () => {
    const { productId } = useParams<{ productId: string }>();

    const [isFormOpened, setIsFormOpened] = useState<boolean>(false);

    const onClose = () => {
        setIsFormOpened(false);
    };
    const { supportPage } = useContext(Content);
    const {
        productSupport: {
            metaTitle,
            metaDescription,
            mainSectionTitle,
            faqTitle,
            contactTitle,
            emptyFaqContactTitle,
            contactButton,
        },
        pageTitle,
    } = supportPage;

    const { loading, error, data } = useQuery<GetProductForSupportPageData>(
        GET_PRODUCT_FOR_SUPPORT_PAGE,
        {
            variables: {
                id: productId,
            },
        },
    );
    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <ErrorMsg type={"default"} />;
    }
    if (data && data.product) {
        const { content, introBackgroundImage, productCardImage } =
            data.product;

        const path = `/support/${productId}`;
        const _metaDescription = metaDescription.replace(
            "%{PRODUCTNAME}",
            content.name,
        );
        const ogImageThumbnail: Thumbnail = findClosestThumbnail(
            productCardImage.content.thumbnails,
        );
        const ogImageUrl =
            productCardImage.content.sourceUrl + ogImageThumbnail.fileName;

        const introBg = makeBackgroundImgUrl(introBackgroundImage);
        return (
            <>
                <MetaTags
                    path={path}
                    title={metaTitle || `${content.name} ${pageTitle}`}
                    description={_metaDescription}
                    openGraphImage={[ogImageUrl, ogImageThumbnail.fileName]}
                />

                {content.faq ? (
                    <HtmlGoogleStructuredData
                        data={{
                            type: "FAQ",
                            faq: content.faq,
                        }}
                    />
                ) : null}

                <div
                    className={intro}
                    style={{
                        background: `url(${introBg}) center /cover no-repeat ${theme.colors.placeholder} `,
                    }}
                >
                    <h1
                        className={productIntroTitle}
                    >{`${content.name} ${pageTitle}`}</h1>
                </div>
                <div className={container.flex800}>
                    {content.faq && (
                        <div className={faqBox}>
                            <h2 className={commonSectionTitle}>
                                {mainSectionTitle}
                            </h2>
                            <h3 className={accordionSubtitle}>{faqTitle}</h3>

                            {content.faq.map((item, index) => (
                                <AccordionItem
                                    key={item.question}
                                    faq={item}
                                    id={`element-${index}`}
                                />
                            ))}
                        </div>
                    )}

                    {!isFormOpened ? (
                        <div className={contactBox}>
                            {content.faq ? (
                                <h3 className={contactsTitle}>
                                    {contactTitle}
                                </h3>
                            ) : (
                                <p className={emptyFaqNotice}>
                                    {emptyFaqContactTitle}
                                </p>
                            )}

                            <button
                                onClick={() => {
                                    setIsFormOpened(true);
                                }}
                                className={anchorButton}
                            >
                                {contactButton}
                            </button>
                        </div>
                    ) : null}

                    {isFormOpened ? (
                        <SupportForm
                            onClose={onClose}
                            supportPageData={supportPage}
                            productId={productId}
                        />
                    ) : null}
                </div>
            </>
        );
    }
    return <ErrorMsg type={"default"} />;
};

export default ProductSupport;
